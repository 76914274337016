import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { KanbanItem as KanbanItemType, LeadStatus, DealStatus } from './kanban-board'
import { KanbanItem } from './kanban-item'

interface Props<T extends LeadStatus | DealStatus> {
  status: T
  title: string
  items: KanbanItemType<T>[]
  activeItem: KanbanItemType<T> | null
  onEdit?: (item: KanbanItemType<T>) => void
}

export function KanbanColumn<T extends LeadStatus | DealStatus>({
  status,
  title,
  items,
  activeItem,
  onEdit,
}: Props<T>) {
  const { setNodeRef, isOver } = useDroppable({
    id: status,
  })

  return (
    <div
      ref={setNodeRef}
      className={`
        flex w-80 shrink-0 flex-col rounded-lg bg-gray-50 p-2
        h-[calc(100vh-12rem)] min-h-[500px]
        ${isOver ? 'ring-2 ring-blue-500' : ''}
      `}
    >
      <div className="mb-3 flex items-center justify-between p-2">
        <h3 className="font-medium">{title}</h3>
        <span className="rounded bg-white px-2 py-1 text-sm text-gray-500">
          {items.length}
        </span>
      </div>

      <div className="flex-1 space-y-2 overflow-y-auto pr-2 pb-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
          {items.map((item) => (
            <KanbanItem
              key={item.id}
              item={item}
              isActive={activeItem?.id === item.id}
              onEdit={onEdit}
            />
          ))}
          <div className="flex h-20 items-center justify-center rounded-lg border-2 border-dashed border-gray-200">
            <p className="text-sm text-gray-400">Drop items here</p>
          </div>
        </SortableContext>
      </div>
    </div>
  )
}
