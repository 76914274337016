import { useState, useCallback, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import type { EventClickArg, DateSelectArg } from '@fullcalendar/core';
import type { CalendarEvent } from '../../types/api';
import { getEventColor } from '../../hooks/useCalendar';
import { useTranslation } from 'react-i18next';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

const TIMEZONE = 'Europe/Berlin';

interface CalendarViewProps {
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
  onSelectSlot: (start: Date, end: Date) => void;
  isLoading?: boolean;
}

export function CalendarView({
  events,
  onEventClick,
  onSelectSlot,
  isLoading,
}: CalendarViewProps) {
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const { t } = useTranslation();

  const calendarEvents = useMemo(() => {
    if (!isLoading) {
      return events.map(event => {
        // Convert UTC dates to Berlin timezone for display
        const startTime = utcToZonedTime(new Date(event.startTime), TIMEZONE);
        const endTime = utcToZonedTime(new Date(event.endTime), TIMEZONE);
        
        // Calculate if it's an all-day event (24 hours or more)
        const duration = endTime.getTime() - startTime.getTime();
        const isAllDay = duration >= 24 * 60 * 60 * 1000;

        return {
          id: event._id,
          title: event.title,
          start: startTime,
          end: endTime,
          className: getEventColor(event),
          extendedProps: event,
          allDay: isAllDay,
        };
      });
    }
    return [];
  }, [events, isLoading]);

  const handleEventClick = useCallback((arg: EventClickArg) => {
    const event = events.find(e => e._id === arg.event.id);
    if (event) {
      onEventClick(event);
    }
  }, [events, onEventClick]);

  const handleSelect = useCallback((arg: DateSelectArg) => {
    // Convert selected dates to Berlin timezone
    const startDate = arg.start;
    const endDate = arg.end;
    
    if (arg.allDay) {
      // For all-day selections, set default time to 9 AM - 10 AM
      startDate.setHours(9, 0, 0);
      endDate.setHours(10, 0, 0);
    } else {
      // For time-specific selections, ensure minimum 30-minute duration
      const duration = endDate.getTime() - startDate.getTime();
      if (duration < 30 * 60 * 1000) {
        endDate.setTime(startDate.getTime() + 30 * 60 * 1000);
      }
    }

    // Convert to UTC before passing to parent
    const utcStart = zonedTimeToUtc(startDate, TIMEZONE);
    const utcEnd = zonedTimeToUtc(endDate, TIMEZONE);
    
    onSelectSlot(utcStart, utcEnd);
  }, [onSelectSlot]);

  const handleLoading = useCallback((isCalendarLoading: boolean) => {
    setCalendarLoaded(!isCalendarLoading);
  }, []);

  if (!calendarLoaded && isLoading) {
    return (
      <div className="h-[800px] bg-white rounded-lg shadow flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="h-[800px] bg-white rounded-lg shadow relative">
      <div className="rounded-lg shadow-sm h-full">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={deLocale}
          timeZone={TIMEZONE}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          buttonText={{
            today: t('calendar.today'),
            month: t('calendar.month'),
            week: t('calendar.week'),
            day: t('calendar.day'),
          }}
          events={calendarEvents}
          editable={false}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          eventClick={handleEventClick}
          select={handleSelect}
          height="100%"
          loading={handleLoading}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            meridiem: false
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            meridiem: false
          }}
          allDaySlot={true}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          nextDayThreshold="00:00:00"
          selectMinDistance={5} // Minimum pixels for time selection
          selectConstraint={{ // Restrict selections to valid times
            startTime: '00:00',
            endTime: '24:00',
          }}
          views={{
            dayGridMonth: {
              dayMaxEventRows: 4,
            },
            timeGridWeek: {
              dayHeaderFormat: { weekday: 'short', day: '2-digit', month: '2-digit', omitCommas: true },
              slotDuration: '00:30:00', // 30-minute slots
              slotLabelInterval: '01:00', // Show hour labels
            },
            timeGridDay: {
              dayHeaderFormat: { weekday: 'long', day: '2-digit', month: 'long' },
              slotDuration: '00:30:00', // 30-minute slots
              slotLabelInterval: '01:00', // Show hour labels
            },
          }}
        />
      </div>
    </div>
  );
}
