import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import type { Lead } from '../../types/api';

interface LeadFormProps {
  initialData?: Partial<Lead>;
  onSubmit: (data: Partial<Lead> & { consultationDate?: string }) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
}

export function LeadForm({ initialData, onSubmit, onCancel, isLoading }: LeadFormProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    name: initialData?.name || '',
    email: initialData?.email || '',
    phone: initialData?.phone || '',
    company: initialData?.company || '',
    status: initialData?.status || 'erste-anfrage',
    priority: initialData?.priority || 'medium',
    consultationDate: initialData?.consultationDate ? 
      new Date(initialData.consultationDate).toISOString().slice(0, 16) : 
      new Date().toISOString().slice(0, 16)
  });

  // Show/hide consultation date based on status
  const [showConsultationDate, setShowConsultationDate] = useState(formData.status === 'beratung-geplant');

  useEffect(() => {
    setShowConsultationDate(formData.status === 'beratung-geplant');
    // Clear consultation date if status is not 'beratung-geplant'
    if (formData.status !== 'beratung-geplant') {
      setFormData(prev => ({ 
        ...prev, 
        consultationDate: new Date().toISOString().slice(0, 16)
      }));
    }
  }, [formData.status]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      // Only include consultationDate if status is 'beratung-geplant'
      ...(formData.status === 'beratung-geplant' && formData.consultationDate
        ? { 
            consultationDate: new Date(formData.consultationDate).toISOString()
          }
        : {})
    };
    await onSubmit(submissionData);
  };

  const handleChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">{t('common.name')}</label>
        <Input
          value={formData.title}
          onChange={e => handleChange('title', e.target.value)}
          placeholder={t('leads.newLead')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('leads.contactInfo')}</label>
        <Input
          value={formData.name}
          onChange={e => handleChange('name', e.target.value)}
          placeholder={t('common.name')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('common.email')}</label>
        <Input
          type="email"
          value={formData.email}
          onChange={e => handleChange('email', e.target.value)}
          placeholder={t('common.email')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('common.phone')}</label>
        <Input
          type="tel"
          value={formData.phone}
          onChange={e => handleChange('phone', e.target.value)}
          placeholder={t('common.phone')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('customers.company')}</label>
        <Input
          value={formData.company}
          onChange={e => handleChange('company', e.target.value)}
          placeholder={t('customers.company')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('common.status')}</label>
        <Select
          value={formData.status}
          onValueChange={(value: Lead['status']) => handleChange('status', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder={t('common.status')} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="erste-anfrage">{t('leads.statuses.erste_anfrage')}</SelectItem>
            <SelectItem value="in-bearbeitung">{t('leads.statuses.in_bearbeitung')}</SelectItem>
            <SelectItem value="beratung-geplant">{t('leads.statuses.beratung_geplant')}</SelectItem>
            <SelectItem value="abgeschlossen">{t('leads.statuses.abgeschlossen')}</SelectItem>
            <SelectItem value="kein-interesse">{t('leads.statuses.kein_interesse')}</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {showConsultationDate && (
        <div>
          <label className="block text-sm font-medium mb-1">{t('leads.nextFollowUp')}</label>
          <Input
            type="datetime-local"
            value={formData.consultationDate}
            onChange={e => handleChange('consultationDate', e.target.value)}
            className="w-full"
            required={showConsultationDate}
          />
        </div>
      )}

      <div>
        <label className="block text-sm font-medium mb-1">{t('leads.priority')}</label>
        <Select
          value={formData.priority}
          onValueChange={(value: Lead['priority']) => handleChange('priority', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder={t('leads.priority')} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="low">{t('leads.priorities.low')}</SelectItem>
            <SelectItem value="medium">{t('leads.priorities.medium')}</SelectItem>
            <SelectItem value="high">{t('leads.priorities.high')}</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <Button type="button" variant="outline" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? t('common.loading') : initialData ? t('common.save') : t('common.create')}
        </Button>
      </div>
    </form>
  );
}