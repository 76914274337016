import { useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Checkbox } from '../ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { format, parseISO, addHours } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import type { CalendarEvent, Lead, Deal, Customer } from '../../types/api';

const TIMEZONE = 'Europe/Berlin';

interface CalendarEventFormProps {
  initialData?: Partial<CalendarEvent>;
  onSubmit: (data: Partial<CalendarEvent>) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
  leads?: Lead[];
  deals?: Deal[];
  customers?: Customer[];
}

interface Attendee {
  name: string;
  email: string;
  status: 'pending' | 'accepted' | 'declined';
}

interface Reminder {
  type: 'email' | 'notification';
  time: number;
}

type RelatedToType = 'lead' | 'deal' | 'customer';

export function CalendarEventForm({
  initialData,
  onSubmit,
  onCancel,
  isLoading,
  leads,
  deals,
  customers,
}: CalendarEventFormProps) {
  // Convert initial dates from UTC to Berlin timezone
  const getInitialDate = (date: string | undefined, defaultOffset: number = 0) => {
    if (date) {
      const berlinTime = utcToZonedTime(new Date(date), TIMEZONE);
      return format(berlinTime, "yyyy-MM-dd'T'HH:mm");
    }
    const now = utcToZonedTime(addHours(new Date(), defaultOffset), TIMEZONE);
    return format(now, "yyyy-MM-dd'T'HH:mm");
  };

  // Calculate if initial event is multi-day
  const isInitialMultiDay = initialData?.startTime && initialData?.endTime
    ? new Date(initialData.endTime).getTime() - new Date(initialData.startTime).getTime() >= 24 * 60 * 60 * 1000
    : false;

  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    description: initialData?.description || '',
    startTime: getInitialDate(initialData?.startTime),
    endTime: getInitialDate(initialData?.endTime, 1),
    type: initialData?.type || 'meeting',
    status: initialData?.status || 'scheduled',
    priority: initialData?.priority || 'medium',
    location: initialData?.location || '',
    virtualMeetingUrl: initialData?.virtualMeetingUrl || '',
    organizer: initialData?.organizer || '',
    attendees: initialData?.attendees || [],
    relatedTo: initialData?.relatedTo,
    reminders: initialData?.reminders || [],
    notes: initialData?.notes || '',
  });

  const [isMultiDay, setIsMultiDay] = useState(isInitialMultiDay);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    const startDate = parseISO(formData.startTime);
    const endDate = parseISO(formData.endTime);

    if (endDate <= startDate) {
      errors.endTime = 'End time must be after start time';
    }

    if (!isMultiDay && endDate.getTime() - startDate.getTime() > 24 * 60 * 60 * 1000) {
      errors.endTime = 'Single-day events cannot exceed 24 hours';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    // Convert dates from Berlin timezone to UTC for submission
    const startDate = zonedTimeToUtc(parseISO(formData.startTime), TIMEZONE);
    const endDate = zonedTimeToUtc(parseISO(formData.endTime), TIMEZONE);

    // If multi-day event, ensure end time is set to end of day
    if (isMultiDay) {
      endDate.setHours(23, 59, 59);
    }

    const submissionData = {
      ...formData,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
    };

    await onSubmit(submissionData);
  };

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    // Clear error when field is updated
    if (formErrors[field]) {
      setFormErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleMultiDayChange = (checked: boolean) => {
    setIsMultiDay(checked);
    if (checked) {
      // If switching to multi-day, set end time to end of the same day
      const endDate = parseISO(formData.startTime);
      endDate.setHours(23, 59, 59);
      handleChange('endTime', format(endDate, "yyyy-MM-dd'T'HH:mm"));
    } else {
      // If switching to single day, set end time to 1 hour after start
      const endDate = addHours(parseISO(formData.startTime), 1);
      handleChange('endTime', format(endDate, "yyyy-MM-dd'T'HH:mm"));
    }
  };

  const handleStartTimeChange = (value: string) => {
    handleChange('startTime', value);
    if (!isMultiDay) {
      // For single-day events, keep 1-hour duration
      const endDate = addHours(parseISO(value), 1);
      handleChange('endTime', format(endDate, "yyyy-MM-dd'T'HH:mm"));
    }
  };

  const handleAddAttendee = () => {
    const newAttendee: Attendee = {
      name: '',
      email: '',
      status: 'pending',
    };
    setFormData(prev => ({
      ...prev,
      attendees: [...prev.attendees, newAttendee],
    }));
  };

  const handleRemoveAttendee = (index: number) => {
    setFormData(prev => ({
      ...prev,
      attendees: prev.attendees.filter((_, i) => i !== index),
    }));
  };

  const handleAttendeeChange = (index: number, field: keyof Attendee, value: string) => {
    setFormData(prev => ({
      ...prev,
      attendees: prev.attendees.map((attendee, i) =>
        i === index ? { ...attendee, [field]: value } : attendee
      ),
    }));
  };

  const handleAddReminder = () => {
    const newReminder: Reminder = {
      type: 'notification',
      time: 15,
    };
    setFormData(prev => ({
      ...prev,
      reminders: [...prev.reminders, newReminder],
    }));
  };

  const handleRemoveReminder = (index: number) => {
    setFormData(prev => ({
      ...prev,
      reminders: prev.reminders.filter((_, i) => i !== index),
    }));
  };

  const handleReminderChange = (index: number, field: keyof Reminder, value: any) => {
    setFormData(prev => ({
      ...prev,
      reminders: prev.reminders.map((reminder, i) =>
        i === index ? { ...reminder, [field]: value } : reminder
      ),
    }));
  };

  const handleRelatedToTypeChange = (value: string) => {
    if (value === 'none') {
      handleChange('relatedTo', undefined);
    } else {
      handleChange('relatedTo', { type: value as RelatedToType, id: '' });
    }
  };

  const getRelatedItems = () => {
    if (!formData.relatedTo?.type) return [];
    
    switch (formData.relatedTo.type) {
      case 'lead':
        return leads || [];
      case 'deal':
        return deals || [];
      case 'customer':
        return customers || [];
      default:
        return [];
    }
  };

  const getItemName = (item: Lead | Deal | Customer) => {
    if ('name' in item) {
      return item.name;
    }
    if ('title' in item) {
      return item.title;
    }
    return 'Unknown';
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">Title</label>
        <Input
          value={formData.title}
          onChange={e => handleChange('title', e.target.value)}
          placeholder="Event title"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Description</label>
        <textarea
          value={formData.description}
          onChange={e => handleChange('description', e.target.value)}
          placeholder="Event description"
          className="w-full min-h-[100px] px-3 py-2 rounded-md border"
          required
        />
      </div>

      <div className="flex items-center space-x-2 mb-4">
        <Checkbox
          id="multiDay"
          checked={isMultiDay}
          onCheckedChange={handleMultiDayChange}
        />
        <label htmlFor="multiDay" className="text-sm font-medium">
          Multi-day event
        </label>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Start Time</label>
          <Input
            type="datetime-local"
            value={formData.startTime}
            onChange={e => handleStartTimeChange(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">End Time</label>
          <Input
            type="datetime-local"
            value={formData.endTime}
            onChange={e => handleChange('endTime', e.target.value)}
            required
            min={formData.startTime}
          />
          {formErrors.endTime && (
            <p className="text-sm text-red-500 mt-1">{formErrors.endTime}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Type</label>
          <Select
            value={formData.type}
            onValueChange={(value: CalendarEvent['type']) => handleChange('type', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="meeting">Meeting</SelectItem>
              <SelectItem value="task">Task</SelectItem>
              <SelectItem value="call">Call</SelectItem>
              <SelectItem value="deadline">Deadline</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Status</label>
          <Select
            value={formData.status}
            onValueChange={(value: CalendarEvent['status']) => handleChange('status', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="scheduled">Scheduled</SelectItem>
              <SelectItem value="in-progress">In Progress</SelectItem>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="cancelled">Cancelled</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Priority</label>
          <Select
            value={formData.priority}
            onValueChange={(value: CalendarEvent['priority']) => handleChange('priority', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select priority" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="low">Low</SelectItem>
              <SelectItem value="medium">Medium</SelectItem>
              <SelectItem value="high">High</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Location</label>
          <Input
            value={formData.location}
            onChange={e => handleChange('location', e.target.value)}
            placeholder="Physical location (optional)"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Virtual Meeting URL</label>
          <Input
            value={formData.virtualMeetingUrl}
            onChange={e => handleChange('virtualMeetingUrl', e.target.value)}
            placeholder="Meeting link (optional)"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Organizer</label>
        <Input
          value={formData.organizer}
          onChange={e => handleChange('organizer', e.target.value)}
          placeholder="Event organizer"
          required
        />
      </div>

      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium">Attendees</label>
          <Button type="button" variant="outline" size="sm" onClick={handleAddAttendee}>
            Add Attendee
          </Button>
        </div>
        <div className="space-y-2">
          {formData.attendees.map((attendee, index) => (
            <div key={index} className="flex gap-2 items-end">
              <Input
                value={attendee.name}
                onChange={e => handleAttendeeChange(index, 'name', e.target.value)}
                placeholder="Name"
                required
              />
              <Input
                type="email"
                value={attendee.email}
                onChange={e => handleAttendeeChange(index, 'email', e.target.value)}
                placeholder="Email"
                required
              />
              <Select
                value={attendee.status}
                onValueChange={(value: Attendee['status']) => handleAttendeeChange(index, 'status', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="pending">Pending</SelectItem>
                  <SelectItem value="accepted">Accepted</SelectItem>
                  <SelectItem value="declined">Declined</SelectItem>
                </SelectContent>
              </Select>
              <Button
                type="button"
                variant="outline"
                onClick={() => handleRemoveAttendee(index)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium">Reminders</label>
          <Button type="button" variant="outline" size="sm" onClick={handleAddReminder}>
            Add Reminder
          </Button>
        </div>
        <div className="space-y-2">
          {formData.reminders.map((reminder, index) => (
            <div key={index} className="flex gap-2 items-end">
              <Select
                value={reminder.type}
                onValueChange={(value: Reminder['type']) => handleReminderChange(index, 'type', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="email">Email</SelectItem>
                  <SelectItem value="notification">Notification</SelectItem>
                </SelectContent>
              </Select>
              <Input
                type="number"
                value={reminder.time}
                onChange={e => handleReminderChange(index, 'time', parseInt(e.target.value))}
                placeholder="Minutes before"
                min="0"
                required
              />
              <Button
                type="button"
                variant="outline"
                onClick={() => handleRemoveReminder(index)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div>
      </div>

      {(leads || deals || customers) && (
        <div>
          <label className="block text-sm font-medium mb-1">Related To</label>
          <div className="grid grid-cols-2 gap-4">
            <Select
              value={formData.relatedTo?.type || 'none'}
              onValueChange={handleRelatedToTypeChange}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="none">None</SelectItem>
                {leads && <SelectItem value="lead">Lead</SelectItem>}
                {deals && <SelectItem value="deal">Deal</SelectItem>}
                {customers && <SelectItem value="customer">Customer</SelectItem>}
              </SelectContent>
            </Select>

            {formData.relatedTo?.type && (
              <Select
                value={formData.relatedTo.id}
                onValueChange={(value: string) =>
                  handleChange('relatedTo', { ...formData.relatedTo, id: value })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select item" />
                </SelectTrigger>
                <SelectContent>
                  {getRelatedItems().map(item => (
                    <SelectItem key={item._id} value={item._id}>
                      {getItemName(item)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium mb-1">Notes</label>
        <textarea
          value={formData.notes}
          onChange={e => handleChange('notes', e.target.value)}
          placeholder="Additional notes"
          className="w-full min-h-[100px] px-3 py-2 rounded-md border"
        />
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Saving...' : initialData ? 'Update Event' : 'Create Event'}
        </Button>
      </div>
    </form>
  );
}
