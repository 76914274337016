import { ColumnDef } from "@tanstack/react-table"
import { useTranslation } from "react-i18next"
import { Lead } from "../types/api"
import { Badge } from "../components/ui/badge"
import { Button } from "../components/ui/button"
import { MoreHorizontal, ArrowUpDown } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { formatDate } from "../lib/utils"

const statusColorMap: Record<Lead['status'], string> = {
  'erste-anfrage': 'bg-blue-500',
  'in-bearbeitung': 'bg-yellow-500',
  'beratung-geplant': 'bg-purple-500',
  'abgeschlossen': 'bg-green-500',
  'kein-interesse': 'bg-red-500',
}

const priorityColorMap: Record<Lead['priority'], string> = {
  low: 'bg-gray-500',
  medium: 'bg-yellow-500',
  high: 'bg-red-500',
}

interface LeadColumnProps {
  onStatusChange: (leadId: string, status: Lead['status']) => Promise<void>;
  onEdit: (lead: Lead) => void;
  onView: (lead: Lead) => void;
  onDelete: (lead: Lead) => void;
}

export const createColumns = ({
  onStatusChange,
  onEdit,
  onView,
  onDelete,
}: LeadColumnProps): ColumnDef<Lead>[] => {
  const { t } = useTranslation()
  
  return [
    {
      accessorKey: "title",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t('common.name')}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t('leads.contactInfo')}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: "company",
      header: t('customers.company'),
    },
    {
      accessorKey: "email",
      header: t('common.email'),
    },
    {
      accessorKey: "status",
      header: t('leads.status'),
      cell: ({ row }) => {
        const lead = row.original
        const status = lead.status

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 px-2">
                <Badge className={`${statusColorMap[status]} text-white`}>
                  {t(`leads.statuses.${status.replace(/-/g, '_')}`)}
                </Badge>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t('common.status')}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {Object.keys(statusColorMap).map((s) => (
                <DropdownMenuItem
                  key={s}
                  onClick={() => onStatusChange(lead._id, s as Lead['status'])}
                >
                  <Badge className={`${statusColorMap[s as Lead['status']]} text-white mr-2`}>
                    {t(`leads.statuses.${s.replace(/-/g, '_')}`)}
                  </Badge>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
    {
      accessorKey: "priority",
      header: t('leads.priority'),
      cell: ({ row }) => {
        const priority = row.getValue("priority") as Lead['priority']
        return (
          <Badge className={`${priorityColorMap[priority]} text-white`}>
            {t(`leads.priorities.${priority}`)}
          </Badge>
        )
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t('common.date')}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => formatDate(row.getValue("createdAt")),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const lead = row.original

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">{t('common.actions')}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => onView(lead)}>
                {t('common.details')}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onEdit(lead)}>
                {t('common.edit')}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => onDelete(lead)}
                className="text-red-600"
              >
                {t('common.delete')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]
}
