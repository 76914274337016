import axios, { AxiosError } from 'axios';
import type {
  Lead,
  Deal,
  Customer,
  CalendarEvent,
  ApiResponse,
  CreateLeadDto,
  UpdateLeadDto,
  CreateDealDto,
  UpdateDealDto,
  CreateCustomerDto,
  UpdateCustomerDto,
  CreateCalendarEventDto,
  UpdateCalendarEventDto,
} from '../types/api';

const API_BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:5001/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Auth token interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Auth API
export const authApi = {
  login: async (email: string, password: string) => {
    try {
      const response = await api.post<ApiResponse<{ token: string; user: any }>>('/auth/login', {
        email,
        password,
      });
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  logout: () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
  },

  getCurrentUser: async () => {
    try {
      const response = await api.get<ApiResponse<any>>('/auth/me');
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  getUsers: async () => {
    try {
      const response = await api.get<ApiResponse<{ users: any[] }>>('/auth/users');
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  register: async (userData: any) => {
    try {
      const response = await api.post<ApiResponse<any>>('/auth/register', userData);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  updateUser: async (userId: string, data: any) => {
    try {
      const response = await api.put<ApiResponse<any>>(`/auth/users/${userId}`, data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

// Leads API
export const leadsApi = {
  getAll: async () => {
    try {
      const response = await api.get<ApiResponse<Lead[]>>('/leads');
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  getById: async (id: string) => {
    try {
      const response = await api.get<ApiResponse<Lead>>(`/leads/${id}`);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  create: async (data: CreateLeadDto) => {
    try {
      const response = await api.post<ApiResponse<Lead>>('/leads', data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  update: async (id: string, data: UpdateLeadDto) => {
    try {
      const response = await api.put<ApiResponse<Lead>>(`/leads/${id}`, data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  delete: async (id: string) => {
    try {
      const response = await api.delete<ApiResponse<void>>(`/leads/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  convertToDeal: async (id: string, data: any) => {
    try {
      const response = await api.post<ApiResponse<Deal>>(`/leads/${id}/convert`, data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  updateStatus: async (id: string, status: string) => {
    try {
      const response = await api.put<ApiResponse<Lead>>(`/leads/${id}/status`, { status });
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

// Deals API
export const dealsApi = {
  getAll: async () => {
    try {
      const response = await api.get<ApiResponse<Deal[]>>('/deals');
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  getById: async (id: string) => {
    try {
      const response = await api.get<ApiResponse<Deal>>(`/deals/${id}`);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  create: async (data: CreateDealDto) => {
    try {
      const response = await api.post<ApiResponse<Deal>>('/deals', data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  update: async (id: string, data: UpdateDealDto) => {
    try {
      const response = await api.put<ApiResponse<Deal>>(`/deals/${id}`, data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  delete: async (id: string) => {
    try {
      const response = await api.delete<ApiResponse<void>>(`/deals/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  updateStage: async (id: string, stage: string) => {
    try {
      const response = await api.put<ApiResponse<Deal>>(`/deals/${id}/stage`, { stage });
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

// Customers API
export const customersApi = {
  getAll: async () => {
    try {
      const response = await api.get<ApiResponse<Customer[]>>('/customers');
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  getById: async (id: string) => {
    try {
      const response = await api.get<ApiResponse<Customer>>(`/customers/${id}`);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  create: async (data: CreateCustomerDto) => {
    try {
      const response = await api.post<ApiResponse<Customer>>('/customers', data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  update: async (id: string, data: UpdateCustomerDto) => {
    try {
      const response = await api.put<ApiResponse<Customer>>(`/customers/${id}`, data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  delete: async (id: string) => {
    try {
      const response = await api.delete<ApiResponse<void>>(`/customers/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  bulkImport: async (data: any[]) => {
    try {
      const response = await api.post<ApiResponse<Customer[]>>('/customers/bulk', data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

// Calendar API
export const calendarApi = {
  getAll: async (start?: Date, end?: Date) => {
    try {
      const params: { start?: string; end?: string } = {};
      if (start) params.start = start.toISOString();
      if (end) params.end = end.toISOString();

      const response = await api.get<ApiResponse<CalendarEvent[]>>('/calendar', { params });
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  getById: async (id: string) => {
    try {
      const response = await api.get<ApiResponse<CalendarEvent>>(`/calendar/${id}`);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  create: async (data: CreateCalendarEventDto) => {
    try {
      // Validate required fields
      validateCalendarEventData(data);
      
      const response = await api.post<ApiResponse<CalendarEvent>>('/calendar', data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  update: async (id: string, data: UpdateCalendarEventDto) => {
    try {
      // Validate data if present
      if (Object.keys(data).length > 0) {
        validateCalendarEventData(data as Partial<CreateCalendarEventDto>);
      }

      const response = await api.put<ApiResponse<CalendarEvent>>(`/calendar/${id}`, data);
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  delete: async (id: string) => {
    try {
      const response = await api.delete<ApiResponse<void>>(`/calendar/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  },

  getUpcoming: async () => {
    try {
      const response = await api.get<ApiResponse<CalendarEvent[]>>('/calendar/upcoming');
      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

// Validation functions
function validateCalendarEventData(data: Partial<CreateCalendarEventDto>) {
  const errors: string[] = [];

  // Required fields
  if ('title' in data && !data.title?.trim()) {
    errors.push('Title is required');
  }
  if ('description' in data && !data.description?.trim()) {
    errors.push('Description is required');
  }
  if ('startTime' in data && !data.startTime) {
    errors.push('Start time is required');
  }
  if ('endTime' in data && !data.endTime) {
    errors.push('End time is required');
  }
  if ('type' in data && !data.type) {
    errors.push('Event type is required');
  }
  if ('organizer' in data && !data.organizer?.trim()) {
    errors.push('Organizer is required');
  }

  // Date validation
  if (data.startTime && data.endTime) {
    const start = new Date(data.startTime);
    const end = new Date(data.endTime);
    if (end <= start) {
      errors.push('End time must be after start time');
    }
  }

  // Type validation
  if (data.type && !['meeting', 'task', 'call', 'deadline'].includes(data.type)) {
    errors.push('Invalid event type');
  }

  // Status validation
  if (data.status && !['scheduled', 'in-progress', 'completed', 'cancelled'].includes(data.status)) {
    errors.push('Invalid event status');
  }

  // Priority validation
  if (data.priority && !['low', 'medium', 'high'].includes(data.priority)) {
    errors.push('Invalid priority level');
  }

  // Attendees validation
  if (data.attendees?.length) {
    data.attendees.forEach((attendee, index) => {
      if (!attendee.name?.trim()) {
        errors.push(`Attendee ${index + 1}: Name is required`);
      }
      if (!attendee.email?.trim()) {
        errors.push(`Attendee ${index + 1}: Email is required`);
      }
      if (!['pending', 'accepted', 'declined'].includes(attendee.status)) {
        errors.push(`Attendee ${index + 1}: Invalid status`);
      }
    });
  }

  // Reminders validation
  if (data.reminders?.length) {
    data.reminders.forEach((reminder, index) => {
      if (!['email', 'notification'].includes(reminder.type)) {
        errors.push(`Reminder ${index + 1}: Invalid type`);
      }
      if (typeof reminder.time !== 'number' || reminder.time < 0) {
        errors.push(`Reminder ${index + 1}: Invalid time`);
      }
    });
  }

  if (errors.length > 0) {
    throw new Error(errors.join('\n'));
  }
}

// Error handling
function handleApiError(error: unknown): never {
  if (error instanceof AxiosError) {
    const message = error.response?.data?.error || error.message;
    throw new Error(message);
  }
  if (error instanceof Error) {
    throw error;
  }
  throw new Error('An unknown error occurred');
}

// Error handling interceptor
api.interceptors.response.use(
  response => response,
  (error: AxiosError<any>) => {
    // Handle 401 Unauthorized errors
    if (error.response?.status === 401) {
      // Clear auth data
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      // Only redirect to login if not already on login page
      if (!window.location.pathname.includes('/login')) {
        window.location.href = '/login';
      }
    }
    
    const message = error.response?.data?.error || 'An error occurred';
    console.error('API Error:', message);
    return Promise.reject(new Error(message));
  }
);

export default api;
