import { useState } from "react"
import { Plus, AlertCircle } from "lucide-react"
import { useCustomers } from "../hooks/useCustomers"
import { DataTable } from "../components/data-table"
import { createColumns } from "./customers-columns"
import { CustomerForm } from "../components/customers/customer-form"
import { CustomerDetails } from "../components/customers/customer-details"
import { customersApi } from "../lib/api"
import type { Customer, CreateCustomerDto } from "../types/api"
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui"

export default function Customers() {
  const { customers, isLoading, error, refetch } = useCustomers()
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleCreate = async (data: Partial<Customer>) => {
    try {
      setIsSubmitting(true)
      const createData: CreateCustomerDto = {
        name: data.name!,
        email: data.email!,
        phone: data.phone!,
        company: data.company!,
        address: {
          street: data.address!.street!,
          city: data.address!.city!,
          state: data.address!.state!,
          postalCode: data.address!.postalCode!,
          country: data.address!.country!,
        },
        type: data.type || 'individual',
        status: data.status || 'prospect',
        stage: data.stage || 'lead',
        assignedTo: data.assignedTo!,
        totalRevenue: data.totalRevenue || 0,
        lastContact: data.lastContact ? new Date(data.lastContact).toISOString() : undefined,
        notes: data.notes || '',
        tags: data.tags || [],
      }
      await customersApi.create(createData)
      await refetch()
      setIsCreateModalOpen(false)
    } catch (error) {
      console.error('Failed to create customer:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleUpdate = async (data: Partial<Customer>) => {
    if (!selectedCustomer) return

    try {
      setIsSubmitting(true)
      await customersApi.update(selectedCustomer._id, data)
      await refetch()
      setIsEditModalOpen(false)
    } catch (error) {
      console.error('Failed to update customer:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!selectedCustomer) return

    try {
      setIsSubmitting(true)
      await customersApi.delete(selectedCustomer._id)
      await refetch()
      setIsDeleteDialogOpen(false)
      setSelectedCustomer(null)
    } catch (error) {
      console.error('Failed to delete customer:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleStatusUpdate = async (customerId: string, status: Customer['status']) => {
    try {
      await customersApi.update(customerId, { status })
      await refetch()
    } catch (error) {
      console.error('Failed to update customer status:', error)
    }
  }

  const openEditModal = (customer: Customer) => {
    setSelectedCustomer(customer)
    setIsEditModalOpen(true)
  }

  const openDetailsModal = (customer: Customer) => {
    setSelectedCustomer(customer)
    setIsDetailsModalOpen(true)
  }

  const openDeleteDialog = (customer: Customer) => {
    setSelectedCustomer(customer)
    setIsDeleteDialogOpen(true)
  }

  const columns = createColumns({
    onStatusChange: handleStatusUpdate,
    onEdit: openEditModal,
    onView: openDetailsModal,
    onDelete: openDeleteDialog,
  })

  if (error) {
    return (
      <div className="p-8">
        <div className="rounded-xl border bg-card p-6 text-center">
          <AlertCircle className="mx-auto h-8 w-8 text-red-600" />
          <h3 className="mt-2 text-xl font-semibold text-red-600">Error loading customers</h3>
          <p className="text-sm text-muted-foreground mt-2">{error.message}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Customers</h1>
        <Button className="bg-black hover:bg-gray-800" onClick={() => setIsCreateModalOpen(true)}>
          <Plus className="mr-2 h-4 w-4" />
          Add New Customer
        </Button>
      </div>
      
      <div className="rounded-xl border bg-card p-6">
        <DataTable
          columns={columns}
          data={customers}
          searchColumn="name"
          isLoading={isLoading}
        />
      </div>

      {/* Create Modal */}
      <Dialog open={isCreateModalOpen} onOpenChange={setIsCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Create New Customer</DialogTitle>
          </DialogHeader>
          <CustomerForm
            onSubmit={handleCreate}
            onCancel={() => setIsCreateModalOpen(false)}
            isLoading={isSubmitting}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Edit Customer</DialogTitle>
          </DialogHeader>
          <CustomerForm
            initialData={selectedCustomer || undefined}
            onSubmit={handleUpdate}
            onCancel={() => setIsEditModalOpen(false)}
            isLoading={isSubmitting}
          />
        </DialogContent>
      </Dialog>

      {/* Details Modal */}
      <Dialog open={isDetailsModalOpen} onOpenChange={setIsDetailsModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Customer Details</DialogTitle>
          </DialogHeader>
          {selectedCustomer && <CustomerDetails customer={selectedCustomer} />}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the customer
              and remove it from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Deleting...' : 'Delete'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
