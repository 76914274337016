import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authApi } from '../lib/api';
import { useAuth } from '../hooks/useAuth';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Card } from '../components/ui/card';

type UserRole = 'superuser' | 'admin' | 'agent';
type NonSuperUserRole = Exclude<UserRole, 'superuser'>;

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  active: boolean;
}

interface NewUser {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  role: NonSuperUserRole;
}

interface BrandingSettings {
  appName: string;
  logo: string | null;
  loginLogo: string | null;
  favicon: string | null;
}

export default function Settings() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [newUser, setNewUser] = useState<NewUser>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    role: 'agent',
  });
  const [brandingSettings, setBrandingSettings] = useState<BrandingSettings>({
    appName: 'Sonnenpower',
    logo: null,
    loginLogo: null,
    favicon: null
  });

  useEffect(() => {
    loadUsers();
    loadBrandingSettings();
  }, []);

  const loadUsers = async () => {
    try {
      const data = await authApi.getUsers();
      if (data?.users) {
        setUsers(data.users);
      }
    } catch (err: any) {
      setError(err.message || t('common.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const loadBrandingSettings = async () => {
    try {
      // TODO: Implement API call to load branding settings
      // const data = await api.getBrandingSettings();
      // setBrandingSettings(data);
    } catch (err: any) {
      setError(err.message || t('common.error'));
    }
  };

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    try {
      await authApi.register(newUser);
      setNewUser({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        role: 'agent',
      });
      loadUsers();
    } catch (err: any) {
      setError(err.message || t('common.error'));
    }
  };

  const handleToggleUserStatus = async (userId: string, currentStatus: boolean) => {
    try {
      await authApi.updateUser(userId, { active: !currentStatus });
      loadUsers();
    } catch (err: any) {
      setError(err.message || t('common.error'));
    }
  };

  const handleUpdateRole = async (userId: string, newRole: NonSuperUserRole) => {
    try {
      await authApi.updateUser(userId, { role: newRole });
      loadUsers();
    } catch (err: any) {
      setError(err.message || t('common.error'));
    }
  };

  const handleBrandingChange = async (field: keyof BrandingSettings, value: string) => {
    try {
      const newSettings = { ...brandingSettings, [field]: value };
      setBrandingSettings(newSettings);
      // TODO: Implement API call to update branding settings
      // await api.updateBrandingSettings(newSettings);
    } catch (err: any) {
      setError(err.message || t('common.error'));
    }
  };

  const handleFileUpload = async (field: 'logo' | 'loginLogo' | 'favicon', file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('field', field);

      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(t('common.error'));
      }

      const { url } = await response.json();
      handleBrandingChange(field, url);
    } catch (err: any) {
      setError(err.message || t('common.error'));
    }
  };

  const isUserSuperuser = (user: User): boolean => {
    return user.role === 'superuser';
  };

  if (isLoading) {
    return <div className="p-8">{t('common.loading')}</div>;
  }

  return (
    <div className="p-8 max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-8">{t('settings.userManagement')}</h1>

      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
          {error}
        </div>
      )}

      {/* Branding Settings */}
      <Card className="mb-8 p-6">
        <h2 className="text-xl font-semibold mb-4">{t('settings.branding')}</h2>
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('settings.appName')}
            </label>
            <Input
              value={brandingSettings.appName}
              onChange={(e) => handleBrandingChange('appName', e.target.value)}
              className="max-w-md"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('settings.logo')}
            </label>
            <div className="flex items-center gap-4">
              {brandingSettings.logo && (
                <img src={brandingSettings.logo} alt={t('settings.logo')} className="h-12 w-auto" />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => e.target.files && handleFileUpload('logo', e.target.files[0])}
                className="hidden"
                id="logo-upload"
              />
              <Button
                variant="outline"
                onClick={() => document.getElementById('logo-upload')?.click()}
              >
                {brandingSettings.logo ? t('common.change') : t('settings.uploadLogo')}
              </Button>
              {brandingSettings.logo && (
                <Button
                  variant="outline"
                  onClick={() => handleBrandingChange('logo', '')}
                  className="text-red-600"
                >
                  {t('common.remove')}
                </Button>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('settings.loginLogo')}
            </label>
            <div className="flex items-center gap-4">
              {brandingSettings.loginLogo && (
                <img src={brandingSettings.loginLogo} alt={t('settings.loginLogo')} className="h-12 w-auto" />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => e.target.files && handleFileUpload('loginLogo', e.target.files[0])}
                className="hidden"
                id="login-logo-upload"
              />
              <Button
                variant="outline"
                onClick={() => document.getElementById('login-logo-upload')?.click()}
              >
                {brandingSettings.loginLogo ? t('common.change') : t('settings.uploadLoginLogo')}
              </Button>
              {brandingSettings.loginLogo && (
                <Button
                  variant="outline"
                  onClick={() => handleBrandingChange('loginLogo', '')}
                  className="text-red-600"
                >
                  {t('common.remove')}
                </Button>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('settings.favicon')}
            </label>
            <div className="flex items-center gap-4">
              {brandingSettings.favicon && (
                <img src={brandingSettings.favicon} alt={t('settings.favicon')} className="h-8 w-auto" />
              )}
              <input
                type="file"
                accept="image/x-icon,image/png"
                onChange={(e) => e.target.files && handleFileUpload('favicon', e.target.files[0])}
                className="hidden"
                id="favicon-upload"
              />
              <Button
                variant="outline"
                onClick={() => document.getElementById('favicon-upload')?.click()}
              >
                {brandingSettings.favicon ? t('common.change') : t('settings.uploadFavicon')}
              </Button>
              {brandingSettings.favicon && (
                <Button
                  variant="outline"
                  onClick={() => handleBrandingChange('favicon', '')}
                  className="text-red-600"
                >
                  {t('common.remove')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>

      {/* Create New User Form */}
      <div className="mb-8 p-6 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">{t('settings.createNewUser')}</h2>
        <form onSubmit={handleCreateUser} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input
              placeholder={t('common.email')}
              type="email"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              required
            />
            <Input
              placeholder={t('common.password')}
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              required
            />
            <Input
              placeholder={t('settings.firstName')}
              value={newUser.firstName}
              onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
              required
            />
            <Input
              placeholder={t('settings.lastName')}
              value={newUser.lastName}
              onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
              required
            />
            <select
              className="border rounded-md p-2"
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value as NonSuperUserRole })}
              required
            >
              <option value="agent">{t('settings.roles.agent')}</option>
              {currentUser?.role === 'superuser' && (
                <option value="admin">{t('settings.roles.admin')}</option>
              )}
            </select>
          </div>
          <Button type="submit" className="bg-yellow-500 hover:bg-yellow-600">
            {t('common.create')}
          </Button>
        </form>
      </div>

      {/* Users List */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('common.name')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('common.email')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('settings.role')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('settings.status')}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t('common.actions')}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.firstName} {user.lastName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {isUserSuperuser(user) ? (
                    <span className="text-yellow-600 font-medium">{t('settings.roles.superuser')}</span>
                  ) : (
                    <select
                      className="border rounded p-1"
                      value={user.role}
                      onChange={(e) => handleUpdateRole(user.id, e.target.value as NonSuperUserRole)}
                      disabled={
                        isUserSuperuser(user) ||
                        (currentUser?.role !== 'superuser' && user.role === 'admin')
                      }
                    >
                      <option value="agent">{t('settings.roles.agent')}</option>
                      <option value="admin">{t('settings.roles.admin')}</option>
                    </select>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.active
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {user.active ? t('settings.active') : t('settings.inactive')}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {!isUserSuperuser(user) && (
                    <Button
                      onClick={() => handleToggleUserStatus(user.id, user.active)}
                      variant="outline"
                      className="text-sm"
                    >
                      {user.active ? t('settings.deactivate') : t('settings.activate')}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
