import { useState, useEffect, useCallback } from 'react';
import { calendarApi } from '../lib/api';
import type { CalendarEvent, CreateCalendarEventDto, UpdateCalendarEventDto } from '../types/api';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

interface UseCalendarReturn {
  events: CalendarEvent[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
  fetchEventsInRange: (start: Date, end: Date) => Promise<void>;
  upcomingEvents: CalendarEvent[];
  createEvent: (data: CreateCalendarEventDto) => Promise<CalendarEvent>;
  updateEvent: (id: string, data: UpdateCalendarEventDto) => Promise<CalendarEvent>;
  deleteEvent: (id: string) => Promise<void>;
}

const REFRESH_INTERVAL = 900000; // Refresh every 15 minutes
const TIMEZONE = 'Europe/Berlin';

export function useCalendar(): UseCalendarReturn {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [upcomingEvents, setUpcomingEvents] = useState<CalendarEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [currentRange, setCurrentRange] = useState<{ start: Date; end: Date } | null>(null);

  const fetchData = useCallback(async (start?: Date, end?: Date) => {
    try {
      setIsLoading(true);
      setError(null);

      // Convert dates to UTC before sending to API
      const utcStart = start ? zonedTimeToUtc(start, TIMEZONE) : undefined;
      const utcEnd = end ? zonedTimeToUtc(end, TIMEZONE) : undefined;

      const [eventsData, upcomingData] = await Promise.all([
        calendarApi.getAll(utcStart, utcEnd),
        calendarApi.getUpcoming()
      ]);
      
      if (eventsData) {
        setEvents(eventsData);
      }
      
      if (upcomingData) {
        setUpcomingEvents(upcomingData);
      }
      
      if (start && end) {
        setCurrentRange({ start, end });
      }
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to fetch events');
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchEventsInRange = useCallback(async (start: Date, end: Date) => {
    await fetchData(start, end);
  }, [fetchData]);

  const createEvent = async (data: CreateCalendarEventDto): Promise<CalendarEvent> => {
    try {
      setIsLoading(true);
      setError(null);

      const newEvent = await calendarApi.create(data);
      if (!newEvent) {
        throw new Error('Failed to create event');
      }

      // Refresh events after creation
      await fetchData(currentRange?.start, currentRange?.end);
      return newEvent;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to create event');
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateEvent = async (id: string, data: UpdateCalendarEventDto): Promise<CalendarEvent> => {
    try {
      setIsLoading(true);
      setError(null);

      const updatedEvent = await calendarApi.update(id, data);
      if (!updatedEvent) {
        throw new Error('Failed to update event');
      }

      // Refresh events after update
      await fetchData(currentRange?.start, currentRange?.end);
      return updatedEvent;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to update event');
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteEvent = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      setError(null);

      await calendarApi.delete(id);

      // Refresh events after deletion
      await fetchData(currentRange?.start, currentRange?.end);
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to delete event');
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch and setup auto-refresh
  useEffect(() => {
    const now = new Date();
    const thirtyDaysFromNow = new Date(now);
    thirtyDaysFromNow.setDate(now.getDate() + 30);
    
    fetchData(now, thirtyDaysFromNow).catch(err => {
      console.error('Failed to fetch initial calendar data:', err);
    });

    // Only set up interval if we're not in a test environment
    if (process.env.NODE_ENV !== 'test') {
      const intervalId = setInterval(() => {
        if (currentRange) {
          fetchData(currentRange.start, currentRange.end).catch(err => {
            console.error('Failed to refresh calendar data:', err);
          });
        }
      }, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [fetchData]);

  return {
    events,
    isLoading,
    error,
    refetch: () => fetchData(currentRange?.start, currentRange?.end),
    fetchEventsInRange,
    upcomingEvents,
    createEvent,
    updateEvent,
    deleteEvent,
  };
}

// Helper functions for working with calendar events
export function getEventColor(event: CalendarEvent): string {
  const typeColorMap: Record<CalendarEvent['type'], string> = {
    'meeting': 'bg-blue-500',
    'task': 'bg-green-500',
    'call': 'bg-purple-500',
    'deadline': 'bg-red-500',
  };

  const statusColorMap: Record<CalendarEvent['status'], string> = {
    'scheduled': 'opacity-100',
    'in-progress': 'opacity-100',
    'completed': 'opacity-60',
    'cancelled': 'opacity-40',
  };

  return `${typeColorMap[event.type]} ${statusColorMap[event.status]}`;
}

export function formatEventTime(event: CalendarEvent): string {
  const start = utcToZonedTime(new Date(event.startTime), TIMEZONE);
  const end = utcToZonedTime(new Date(event.endTime), TIMEZONE);
  
  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
    timeZone: TIMEZONE
  };

  return `${start.toLocaleTimeString('de-DE', timeFormat)} - ${end.toLocaleTimeString('de-DE', timeFormat)}`;
}

export function isSameDay(date1: Date, date2: Date): boolean {
  const d1 = utcToZonedTime(date1, TIMEZONE);
  const d2 = utcToZonedTime(date2, TIMEZONE);
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function isEventInRange(event: CalendarEvent, start: Date, end: Date): boolean {
  const eventStart = utcToZonedTime(new Date(event.startTime), TIMEZONE);
  const eventEnd = utcToZonedTime(new Date(event.endTime), TIMEZONE);
  const rangeStart = utcToZonedTime(start, TIMEZONE);
  const rangeEnd = utcToZonedTime(end, TIMEZONE);
  return eventStart >= rangeStart && eventEnd <= rangeEnd;
}
