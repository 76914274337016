import { Badge } from '../ui/badge';
import { Card } from '../ui/card';
import type { Customer } from '../../types/api';

interface CustomerDetailsProps {
  customer: Customer;
}

const statusColorMap: Record<Customer['status'], string> = {
  'active': 'bg-green-500',
  'inactive': 'bg-red-500',
  'prospect': 'bg-blue-500',
};

const typeColorMap: Record<Customer['type'], string> = {
  'individual': 'bg-purple-500',
  'business': 'bg-orange-500',
};

export function CustomerDetails({ customer }: CustomerDetailsProps) {
  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <h2 className="text-2xl font-bold">{customer.name}</h2>
          <div className="mt-2 flex gap-2">
            <Badge className={`${statusColorMap[customer.status]} text-white`}>
              {customer.status}
            </Badge>
            <Badge className={`${typeColorMap[customer.type]} text-white`}>
              {customer.type}
            </Badge>
          </div>
        </div>

        <div className="grid gap-4 pt-4">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Contact Information</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Email:</span>{' '}
                <a href={`mailto:${customer.email}`} className="text-blue-600 hover:underline">
                  {customer.email}
                </a>
              </p>
              <p>
                <span className="font-medium">Phone:</span>{' '}
                <a href={`tel:${customer.phone}`} className="text-blue-600 hover:underline">
                  {customer.phone}
                </a>
              </p>
              <p>
                <span className="font-medium">Company:</span> {customer.company}
              </p>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Address</h3>
            <div className="mt-2 space-y-1">
              <p>{customer.address.street}</p>
              <p>
                {customer.address.city}, {customer.address.state} {customer.address.postalCode}
              </p>
              <p>{customer.address.country}</p>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Business Information</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Assigned To:</span> {customer.assignedTo}
              </p>
              <p>
                <span className="font-medium">Total Revenue:</span>{' '}
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(customer.totalRevenue)}
              </p>
              {customer.lastContact && (
                <p>
                  <span className="font-medium">Last Contact:</span>{' '}
                  {new Date(customer.lastContact).toLocaleDateString()}
                </p>
              )}
            </div>
          </div>

          {customer.tags.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-500">Tags</h3>
              <div className="mt-2 flex flex-wrap gap-2">
                {customer.tags.map((tag, index) => (
                  <Badge key={index} variant="outline">
                    {tag}
                  </Badge>
                ))}
              </div>
            </div>
          )}

          {customer.notes && (
            <div>
              <h3 className="text-sm font-medium text-gray-500">Notes</h3>
              <p className="mt-2 whitespace-pre-wrap">{customer.notes}</p>
            </div>
          )}

          <div>
            <h3 className="text-sm font-medium text-gray-500">Timeline</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Created:</span>{' '}
                {new Date(customer.createdAt).toLocaleDateString()}
              </p>
              <p>
                <span className="font-medium">Last Updated:</span>{' '}
                {new Date(customer.updatedAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
