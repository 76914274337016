import { Link, useLocation, useNavigate } from "react-router-dom"
import { cn } from "../lib/utils"
import {
  LayoutDashboard,
  Users,
  DollarSign,
  UserSquare2,
  Calendar as CalendarIcon,
  LogOut,
  Settings,
} from "lucide-react"
import { useAuth } from "../hooks/useAuth"
import { Button } from "./ui/button"
import { useTranslation } from "react-i18next"

const navigation = [
  {
    name: "navigation.dashboard",
    href: "/dashboard",
    icon: LayoutDashboard,
  },
  {
    name: "navigation.leads",
    href: "/leads",
    icon: Users,
  },
  {
    name: "navigation.deals",
    href: "/deals",
    icon: DollarSign,
  },
  {
    name: "navigation.customers",
    href: "/customers",
    icon: UserSquare2,
  },
  {
    name: "navigation.calendar",
    href: "/calendar",
    icon: CalendarIcon,
  },
]

export function Sidebar() {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const { t } = useTranslation()

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="flex h-full w-[250px] flex-col border-r bg-[#1a2942] text-white px-2">
      <div className="flex h-16 items-center px-4 border-b border-white/10">
        <Link to="/" className="flex items-center gap-2">
          <div className="h-7 w-7 rounded-lg bg-yellow-500" />
          <span className="text-lg font-bold text-yellow-500">Sonnenpower</span>
        </Link>
      </div>
      
      {/* User Profile Section */}
      {user && (
        <div className="px-4 py-4 border-b border-white/10">
          <div className="flex items-center gap-3">
            <div className="h-10 w-10 rounded-full bg-yellow-500 flex items-center justify-center">
              <span className="text-[#1a2942] font-bold">
                {user.firstName?.[0]}{user.lastName?.[0]}
              </span>
            </div>
            <div>
              <div className="font-medium">{user.firstName} {user.lastName}</div>
              <div className="text-xs text-yellow-500">{t(`settings.roles.${user.role}`)}</div>
            </div>
          </div>
        </div>
      )}

      {/* Navigation */}
      <div className="flex-1 space-y-1 px-2 py-4">
        {navigation.map((item) => {
          const isActive = location.pathname === item.href
          const Icon = item.icon

          return (
            <Link
              key={item.name}
              to={item.href}
              className={cn(
                "flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium transition-colors",
                isActive
                  ? "bg-yellow-500 text-[#1a2942]"
                  : "text-white/70 hover:bg-white/10 hover:text-white"
              )}
            >
              <Icon className="h-4 w-4" />
              {t(item.name)}
            </Link>
          )
        })}
      </div>

      {/* Admin Settings (only for admin/superuser) */}
      {user && (user.role === 'admin' || user.role === 'superuser') && (
        <Link
          to="/settings"
          className="flex items-center gap-3 rounded-lg px-5 py-2 text-sm font-medium text-white/70 hover:bg-white/10 hover:text-white mb-2"
        >
          <Settings className="h-4 w-4" />
          {t('navigation.settings')}
        </Link>
      )}

      {/* Logout Button */}
      <div className="p-4 border-t border-white/10">
        <Button
          onClick={handleLogout}
          variant="ghost"
          className="w-full justify-start text-white/70 hover:bg-white/10 hover:text-white"
        >
          <LogOut className="mr-2 h-4 w-4" />
          {t('auth.logout')}
        </Button>
      </div>
    </div>
  )
}
