import { Users, DollarSign, Mail, LineChart, Loader2 } from "lucide-react"
import { Link } from "react-router-dom"
import { Overview } from "../components/overview"
import { RecentSales } from "../components/recent-sales"
import { Button } from "../components/ui/button"
import { useDashboardData } from "../hooks/useDashboardData"
import { formatCurrency } from "../lib/utils"

export default function Dashboard() {
  const { metrics, recentDeals, allDeals, isLoading, error } = useDashboardData();

  if (error) {
    return (
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="rounded-xl border bg-card p-6 text-center">
          <h3 className="text-xl font-semibold text-red-600">Error loading dashboard data</h3>
          <p className="text-sm text-muted-foreground mt-2">{error.message}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex-1 space-y-4 p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
        <div className="flex items-center space-x-2">
          <Button variant="outline">
            {new Date().toLocaleDateString('en-US', { 
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}
          </Button>
        </div>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-xl border bg-card p-6">
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <p className="text-sm font-medium">Total Revenue</p>
            <DollarSign className="h-4 w-4 text-muted-foreground" />
          </div>
          <div className="flex flex-col">
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <>
                <div className="text-2xl font-bold">
                  {formatCurrency(metrics.totalRevenue)}
                </div>
                <p className="text-xs text-muted-foreground">
                  +{metrics.revenueGrowth}% from last month
                </p>
              </>
            )}
          </div>
        </div>
        <div className="rounded-xl border bg-card p-6">
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <p className="text-sm font-medium">Active Leads</p>
            <Users className="h-4 w-4 text-muted-foreground" />
          </div>
          <div className="flex flex-col">
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <>
                <div className="text-2xl font-bold">
                  {metrics.activeLeads.toLocaleString()}
                </div>
                <p className="text-xs text-muted-foreground">
                  +{metrics.leadsGrowth}% from last month
                </p>
              </>
            )}
          </div>
        </div>
        <div className="rounded-xl border bg-card p-6">
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <p className="text-sm font-medium">Deals Closed</p>
            <Mail className="h-4 w-4 text-muted-foreground" />
          </div>
          <div className="flex flex-col">
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <>
                <div className="text-2xl font-bold">
                  {metrics.dealsClosedCount.toLocaleString()}
                </div>
                <p className="text-xs text-muted-foreground">
                  +{metrics.dealsGrowth}% from last month
                </p>
              </>
            )}
          </div>
        </div>
        <div className="rounded-xl border bg-card p-6">
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <p className="text-sm font-medium">Active Customers</p>
            <LineChart className="h-4 w-4 text-muted-foreground" />
          </div>
          <div className="flex flex-col">
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <>
                <div className="text-2xl font-bold">
                  {metrics.activeCustomers.toLocaleString()}
                </div>
                <p className="text-xs text-muted-foreground">
                  +{metrics.customersGrowth}% from last month
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
        <div className="col-span-4">
          <div className="rounded-xl border bg-card">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-2xl font-semibold leading-none tracking-tight">
                Overview
              </h3>
            </div>
            <div className="p-6 pt-0">
              <Overview deals={allDeals} isLoading={isLoading} />
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="rounded-xl border bg-card">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-2xl font-semibold leading-none tracking-tight">
                Recent Sales
              </h3>
            </div>
            <div className="p-6 pt-0">
              <RecentSales deals={recentDeals} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-xl border bg-card p-6">
          <h3 className="text-xl font-semibold mb-2">Leads</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Manage your leads and convert them into customers.
          </p>
          <Link to="/leads">
            <Button className="w-full bg-black text-white hover:bg-gray-800">
              View Leads
            </Button>
          </Link>
        </div>
        <div className="rounded-xl border bg-card p-6">
          <h3 className="text-xl font-semibold mb-2">Deals</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Track and manage your ongoing deals.
          </p>
          <Link to="/deals">
            <Button className="w-full bg-black text-white hover:bg-gray-800">
              View Deals
            </Button>
          </Link>
        </div>
        <div className="rounded-xl border bg-card p-6">
          <h3 className="text-xl font-semibold mb-2">Customers</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Manage your customer relationships effectively.
          </p>
          <Link to="/customers">
            <Button className="w-full bg-black text-white hover:bg-gray-800">
              View Customers
            </Button>
          </Link>
        </div>
        <div className="rounded-xl border bg-card p-6">
          <h3 className="text-xl font-semibold mb-2">Calendar</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Schedule and manage your appointments and events.
          </p>
          <Link to="/calendar">
            <Button className="w-full bg-black text-white hover:bg-gray-800">
              View Calendar
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
