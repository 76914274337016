import { ColumnDef } from "@tanstack/react-table"
import { useTranslation } from "react-i18next"
import { Deal } from "../types/api"
import { Badge } from "../components/ui/badge"
import { Button } from "../components/ui/button"
import { MoreHorizontal, ArrowUpDown } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { formatDate, formatCurrency } from "../lib/utils"

const stageColorMap: Record<Deal['stage'], string> = {
  'angebot-erstellt': 'bg-blue-500',
  'angebot-verhandeln': 'bg-yellow-500',
  'abschlussbereit': 'bg-purple-500',
  'montage-fixiert': 'bg-indigo-500',
  'projekt-fertig': 'bg-green-500',
  'angebot-abgelehnt': 'bg-red-500',
}

const priorityColorMap: Record<Deal['priority'], string> = {
  low: 'bg-gray-500',
  medium: 'bg-yellow-500',
  high: 'bg-red-500',
}

interface DealColumnProps {
  onStageChange: (dealId: string, stage: Deal['stage']) => Promise<void>;
  onEdit: (deal: Deal) => void;
  onView: (deal: Deal) => void;
  onDelete: (deal: Deal) => void;
}

export const createColumns = ({
  onStageChange,
  onEdit,
  onView,
  onDelete,
}: DealColumnProps): ColumnDef<Deal>[] => {
  const { t } = useTranslation()
  
  return [
    {
      accessorKey: "title",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t('common.name')}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: "company",
      header: t('customers.company'),
    },
    {
      accessorKey: "value",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t('deals.value')}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const value = row.getValue("value") as number
        return formatCurrency(value)
      },
    },
    {
      accessorKey: "stage",
      header: t('deals.stage'),
      cell: ({ row }) => {
        const deal = row.original
        const stage = deal.stage

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 px-2">
                <Badge className={`${stageColorMap[stage]} text-white`}>
                  {t(`deals.stages.${stage.replace(/-/g, '_')}`)}
                </Badge>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t('deals.stage')}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {Object.keys(stageColorMap).map((s) => (
                <DropdownMenuItem
                  key={s}
                  onClick={() => onStageChange(deal._id, s as Deal['stage'])}
                >
                  <Badge className={`${stageColorMap[s as Deal['stage']]} text-white mr-2`}>
                    {t(`deals.stages.${s.replace(/-/g, '_')}`)}
                  </Badge>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
    {
      accessorKey: "priority",
      header: t('leads.priority'),
      cell: ({ row }) => {
        const priority = row.getValue("priority") as Deal['priority']
        return (
          <Badge className={`${priorityColorMap[priority]} text-white`}>
            {t(`leads.priorities.${priority}`)}
          </Badge>
        )
      },
    },
    {
      accessorKey: "assignedTo",
      header: t('leads.assignedTo'),
    },
    {
      accessorKey: "expectedCloseDate",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t('deals.expectedCloseDate')}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => formatDate(row.getValue("expectedCloseDate")),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const deal = row.original

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">{t('common.actions')}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => onView(deal)}>
                {t('common.details')}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onEdit(deal)}>
                {t('common.edit')}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => onDelete(deal)}
                className="text-red-600"
              >
                {t('common.delete')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]
}
