import { useState, useEffect } from 'react';
import { customersApi } from '../lib/api';
import type { Customer, CreateCustomerDto } from '../types/api';

interface UseCustomersReturn {
  customers: Customer[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
  bulkImport: (customers: CreateCustomerDto[]) => Promise<void>;
}

export function useCustomers(): UseCustomersReturn {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  async function fetchCustomers() {
    try {
      setIsLoading(true);
      const data = await customersApi.getAll();
      setCustomers(data ?? []);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch customers'));
    } finally {
      setIsLoading(false);
    }
  }

  async function bulkImport(customersData: CreateCustomerDto[]) {
    try {
      setIsLoading(true);
      await customersApi.bulkImport(customersData);
      await fetchCustomers(); // Refresh the list after import
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to bulk import customers'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCustomers();
  }, []);

  return {
    customers,
    isLoading,
    error,
    refetch: fetchCustomers,
    bulkImport
  };
}
