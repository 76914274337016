import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import type { CalendarEvent } from '../../types/api';

const TIMEZONE = 'Europe/Berlin';

interface CalendarEventDetailsProps {
  event: CalendarEvent;
  onDelete: (id: string) => Promise<void>;
}

const eventTypeColorMap: Record<CalendarEvent['type'], string> = {
  'meeting': 'bg-blue-500',
  'task': 'bg-green-500',
  'call': 'bg-purple-500',
  'deadline': 'bg-red-500',
};

const priorityColorMap: Record<CalendarEvent['priority'], string> = {
  low: 'bg-gray-500',
  medium: 'bg-yellow-500',
  high: 'bg-red-500',
};

const statusColorMap: Record<CalendarEvent['status'], string> = {
  'scheduled': 'bg-blue-500',
  'in-progress': 'bg-yellow-500',
  'completed': 'bg-green-500',
  'cancelled': 'bg-gray-500',
};

export function CalendarEventDetails({ event, onDelete }: CalendarEventDetailsProps) {
  // Convert UTC dates to Berlin timezone for display
  const startTime = utcToZonedTime(new Date(event.startTime), TIMEZONE);
  const endTime = utcToZonedTime(new Date(event.endTime), TIMEZONE);

  // Calculate if it's a multi-day event
  const isMultiDay = endTime.getTime() - startTime.getTime() >= 24 * 60 * 60 * 1000;

  const formatDateTime = (date: Date) => {
    if (isMultiDay) {
      return format(date, 'dd.MM.yyyy HH:mm');
    }
    return format(date, 'HH:mm');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      await onDelete(event._id);
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold">{event.title}</h3>
          <div className="flex gap-2">
            <Badge className={`${eventTypeColorMap[event.type]} text-white`}>
              {event.type}
            </Badge>
            <Badge className={`${priorityColorMap[event.priority]} text-white`}>
              {event.priority}
            </Badge>
            <Badge className={`${statusColorMap[event.status]} text-white`}>
              {event.status}
            </Badge>
          </div>
        </div>
        <p className="text-sm text-muted-foreground">{event.description}</p>
      </div>

      <div className="grid gap-4">
        <div>
          <h4 className="font-medium mb-2">Time</h4>
          <div className="text-sm">
            {isMultiDay ? (
              <>
                <p>Start: {formatDateTime(startTime)}</p>
                <p>End: {formatDateTime(endTime)}</p>
              </>
            ) : (
              <p>{format(startTime, 'dd.MM.yyyy')} {formatDateTime(startTime)} - {formatDateTime(endTime)}</p>
            )}
          </div>
        </div>

        {(event.location || event.virtualMeetingUrl) && (
          <div>
            <h4 className="font-medium mb-2">Location</h4>
            <div className="text-sm space-y-1">
              {event.location && <p>{event.location}</p>}
              {event.virtualMeetingUrl && (
                <p>
                  <a 
                    href={event.virtualMeetingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    Virtual Meeting Link
                  </a>
                </p>
              )}
            </div>
          </div>
        )}

        <div>
          <h4 className="font-medium mb-2">Organizer</h4>
          <p className="text-sm">{event.organizer}</p>
        </div>

        {event.attendees.length > 0 && (
          <div>
            <h4 className="font-medium mb-2">Attendees</h4>
            <div className="space-y-2">
              {event.attendees.map((attendee, index) => (
                <div key={index} className="text-sm flex justify-between items-center">
                  <div>
                    <p className="font-medium">{attendee.name}</p>
                    <p className="text-muted-foreground">{attendee.email}</p>
                  </div>
                  <Badge className={
                    attendee.status === 'accepted' ? 'bg-green-500' :
                    attendee.status === 'declined' ? 'bg-red-500' :
                    'bg-yellow-500'
                  }>
                    {attendee.status}
                  </Badge>
                </div>
              ))}
            </div>
          </div>
        )}

        {event.reminders.length > 0 && (
          <div>
            <h4 className="font-medium mb-2">Reminders</h4>
            <div className="space-y-1">
              {event.reminders.map((reminder, index) => (
                <p key={index} className="text-sm">
                  {reminder.type === 'email' ? '📧' : '🔔'} {reminder.time} minutes before
                </p>
              ))}
            </div>
          </div>
        )}

        {event.notes && (
          <div>
            <h4 className="font-medium mb-2">Notes</h4>
            <p className="text-sm whitespace-pre-wrap">{event.notes}</p>
          </div>
        )}
      </div>

      <div className="pt-4">
        <Button 
          variant="destructive" 
          onClick={handleDelete}
          className="w-full"
        >
          Delete Event
        </Button>
      </div>
    </div>
  );
}
