import { useState, useEffect, useCallback } from 'react';
import { leadsApi, dealsApi, customersApi } from '../lib/api';
import type { Deal, Lead, Customer } from '../types/api';

interface DashboardMetrics {
  totalRevenue: number;
  activeLeads: number;
  dealsClosedCount: number;
  activeCustomers: number;
  revenueGrowth: number;
  leadsGrowth: number;
  dealsGrowth: number;
  customersGrowth: number;
}

interface UseDashboardDataReturn {
  metrics: DashboardMetrics;
  recentDeals: Deal[];
  allDeals: Deal[];
  isLoading: boolean;
  error: Error | null;
  refresh: () => Promise<void>;
}

const REFRESH_INTERVAL = 30000; // Refresh every 30 seconds

export function useDashboardData(): UseDashboardDataReturn {
  const [metrics, setMetrics] = useState<DashboardMetrics>({
    totalRevenue: 0,
    activeLeads: 0,
    dealsClosedCount: 0,
    activeCustomers: 0,
    revenueGrowth: 0,
    leadsGrowth: 0,
    dealsGrowth: 0,
    customersGrowth: 0,
  });
  const [recentDeals, setRecentDeals] = useState<Deal[]>([]);
  const [allDeals, setAllDeals] = useState<Deal[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const calculateGrowth = (current: number, previous: number): number => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const calculateMonthlyMetrics = (
    deals: Deal[],
    leads: Lead[],
    customers: Customer[]
  ) => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    // Current month data
    const currentMonthDeals = deals.filter(deal => {
      const dealDate = new Date(deal.updatedAt);
      return dealDate.getMonth() === currentMonth && dealDate.getFullYear() === currentYear;
    });
    const currentMonthLeads = leads.filter(lead => {
      const leadDate = new Date(lead.createdAt);
      return leadDate.getMonth() === currentMonth && leadDate.getFullYear() === currentYear;
    });
    const currentMonthCustomers = customers.filter(customer => {
      const customerDate = new Date(customer.createdAt);
      return customerDate.getMonth() === currentMonth && customerDate.getFullYear() === currentYear;
    });

    // Last month data
    const lastMonthDeals = deals.filter(deal => {
      const dealDate = new Date(deal.updatedAt);
      return dealDate.getMonth() === lastMonth && dealDate.getFullYear() === lastMonthYear;
    });
    const lastMonthLeads = leads.filter(lead => {
      const leadDate = new Date(lead.createdAt);
      return leadDate.getMonth() === lastMonth && leadDate.getFullYear() === lastMonthYear;
    });
    const lastMonthCustomers = customers.filter(customer => {
      const customerDate = new Date(customer.createdAt);
      return customerDate.getMonth() === lastMonth && customerDate.getFullYear() === lastMonthYear;
    });

    // Calculate current month revenue
    const currentRevenue = currentMonthDeals
      .filter(deal => deal.stage === 'projekt-fertig')
      .reduce((sum, deal) => sum + deal.value, 0);

    // Calculate last month revenue
    const lastRevenue = lastMonthDeals
      .filter(deal => deal.stage === 'projekt-fertig')
      .reduce((sum, deal) => sum + deal.value, 0);

    return {
      revenueGrowth: calculateGrowth(currentRevenue, lastRevenue),
      leadsGrowth: calculateGrowth(currentMonthLeads.length, lastMonthLeads.length),
      dealsGrowth: calculateGrowth(currentMonthDeals.length, lastMonthDeals.length),
      customersGrowth: calculateGrowth(currentMonthCustomers.length, lastMonthCustomers.length),
    };
  };

  const fetchDashboardData = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Fetch all required data in parallel
      const [leadsResponse, dealsResponse, customersResponse] = await Promise.all([
        leadsApi.getAll(),
        dealsApi.getAll(),
        customersApi.getAll(),
      ]);

      // Ensure we have data, otherwise use empty arrays
      const leads = leadsResponse ?? [];
      const deals = dealsResponse ?? [];
      const customers = customersResponse ?? [];

      // Calculate current metrics
      const activeLeadsCount = leads.filter(lead => 
        lead.status !== 'abgeschlossen' && lead.status !== 'kein-interesse'
      ).length;

      const closedDeals = deals.filter(deal => deal.stage === 'projekt-fertig');
      const totalRevenue = closedDeals.reduce((sum, deal) => sum + deal.value, 0);
      
      const activeCustomersCount = customers.filter(
        customer => customer.status === 'active'
      ).length;

      // Sort deals by date and get recent ones
      const sortedDeals = [...deals].sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      const recentDealsList = sortedDeals.slice(0, 5);

      // Calculate growth rates
      const growth = calculateMonthlyMetrics(deals, leads, customers);

      setMetrics({
        totalRevenue,
        activeLeads: activeLeadsCount,
        dealsClosedCount: closedDeals.length,
        activeCustomers: activeCustomersCount,
        ...growth
      });

      setRecentDeals(recentDealsList);
      setAllDeals(deals);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch dashboard data'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
    
    // Set up automatic refresh
    const intervalId = setInterval(fetchDashboardData, REFRESH_INTERVAL);
    
    return () => clearInterval(intervalId);
  }, [fetchDashboardData]);

  return { 
    metrics, 
    recentDeals, 
    allDeals, 
    isLoading, 
    error,
    refresh: fetchDashboardData 
  };
}
