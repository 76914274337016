import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Sidebar } from './components/sidebar';
import { AuthProvider } from './context/auth-context';
import { ProtectedRoute } from './components/auth/protected-route';
import Dashboard from './pages/Dashboard';
import Leads from './pages/Leads';
import Deals from './pages/Deals';
import Customers from './pages/Customers';
import Calendar from './pages/Calendar';
import Settings from './pages/Settings';
import { Login } from './pages/Login';
import Unauthorized from './pages/Unauthorized';
import NotFound from './pages/not-found';

// Layout component to handle sidebar and main content
function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <main className="flex-1 overflow-y-auto bg-background">
        {children}
      </main>
    </div>
  );
}

// Protected Layout that includes auth check and layout structure
function ProtectedLayout({ 
  children,
  requiredRoles
}: { 
  children: React.ReactNode;
  requiredRoles?: ('superuser' | 'admin' | 'agent')[];
}) {
  return (
    <ProtectedRoute requiredRoles={requiredRoles}>
      <Layout>{children}</Layout>
    </ProtectedRoute>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* Protected routes */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          
          <Route
            path="/dashboard"
            element={
              <ProtectedLayout>
                <Dashboard />
              </ProtectedLayout>
            }
          />
          
          <Route
            path="/leads"
            element={
              <ProtectedLayout>
                <Leads />
              </ProtectedLayout>
            }
          />
          
          <Route
            path="/deals"
            element={
              <ProtectedLayout>
                <Deals />
              </ProtectedLayout>
            }
          />
          
          <Route
            path="/customers"
            element={
              <ProtectedLayout>
                <Customers />
              </ProtectedLayout>
            }
          />
          
          <Route
            path="/calendar"
            element={
              <ProtectedLayout>
                <Calendar />
              </ProtectedLayout>
            }
          />

          {/* Admin/Superuser only routes */}
          <Route
            path="/settings"
            element={
              <ProtectedLayout requiredRoles={['superuser', 'admin']}>
                <Settings />
              </ProtectedLayout>
            }
          />

          {/* Catch all route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}
