import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useTranslation } from 'react-i18next'
import type { KanbanItem as KanbanItemType, LeadStatus, DealStatus, Priority } from './kanban-board'

export interface KanbanItemProps<T extends LeadStatus | DealStatus> {
  item: KanbanItemType<T>
  isActive?: boolean
  onEdit?: (item: KanbanItemType<T>) => void
}

type PriorityColor = {
  [K in Priority]: string
}

type PriorityTranslationKey = 'leads.priorities.low' | 'leads.priorities.medium' | 'leads.priorities.high'

const priorityColors: PriorityColor = {
  low: 'bg-blue-100 text-blue-700',
  medium: 'bg-yellow-100 text-yellow-700',
  high: 'bg-red-100 text-red-700',
} as const

const priorityTranslationKeys: Record<Priority, PriorityTranslationKey> = {
  low: 'leads.priorities.low',
  medium: 'leads.priorities.medium',
  high: 'leads.priorities.high',
} as const

export function KanbanItem<T extends LeadStatus | DealStatus>({ 
  item, 
  isActive,
  onEdit 
}: KanbanItemProps<T>) {
  const { t } = useTranslation()
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: item.id,
    data: {
      type: 'item',
      item,
    },
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
    touchAction: 'none'
  }

  const handleDoubleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (onEdit) {
      onEdit(item)
    }
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onDoubleClick={handleDoubleClick}
      role="button"
      aria-label={`${item.title} - ${t(priorityTranslationKeys[item.priority])}`}
      tabIndex={0}
      className={`
        select-none touch-none cursor-grab rounded-lg bg-white p-3
        shadow-sm transition-shadow duration-200
        ${isActive ? 'ring-2 ring-blue-500' : ''}
        ${isDragging ? 'opacity-50 shadow-md scale-105' : ''}
        hover:shadow-md hover:bg-gray-50 active:cursor-grabbing
        transform-gpu
      `}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium">{item.title}</span>
          <span
            className={`
              rounded-full px-2 py-1 text-xs font-medium
              ${priorityColors[item.priority]}
            `}
            role="status"
            aria-label={t(priorityTranslationKeys[item.priority])}
          >
            {t(priorityTranslationKeys[item.priority])}
          </span>
        </div>
        {item.subtitle && (
          <span className="text-xs text-gray-500">{item.subtitle}</span>
        )}
      </div>
    </div>
  )
}
