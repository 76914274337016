import i18n from 'i18next';
import { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

// English-only translations
const translations = {
  navigation: {
    dashboard: 'Dashboard',
    leads: 'Leads',
    deals: 'Deals',
    customers: 'Customers',
    calendar: 'Calendar',
    settings: 'Settings'
  },
  auth: {
    login: 'Login',
    logout: 'Logout',
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    loginButton: 'Login'
  },
  settings: {
    userManagement: 'User Management',
    createNewUser: 'Create New User',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    role: 'Role',
    status: 'Status',
    actions: 'Actions',
    active: 'Active',
    inactive: 'Inactive',
    activate: 'Activate',
    deactivate: 'Deactivate',
    branding: 'Branding',
    appName: 'Application Name',
    logo: 'Logo',
    loginLogo: 'Login Logo',
    favicon: 'Favicon',
    uploadLogo: 'Upload Logo',
    uploadLoginLogo: 'Upload Login Logo',
    uploadFavicon: 'Upload Favicon',
    currentLogo: 'Current Logo',
    currentLoginLogo: 'Current Login Logo',
    currentFavicon: 'Current Favicon',
    roles: {
      agent: 'Agent',
      admin: 'Administrator',
      superuser: 'Superuser'
    }
  },
  leads: {
    title: 'Leads',
    newLead: 'New Lead',
    status: 'Status',
    source: 'Source',
    priority: 'Priority',
    assignedTo: 'Assigned To',
    lastContact: 'Last Contact',
    nextFollowUp: 'Next Follow Up',
    addLead: 'Add Lead',
    editLead: 'Edit Lead',
    deleteLead: 'Delete Lead',
    notes: 'Notes',
    contactInfo: 'Contact Info',
    statuses: {
      erste_anfrage: 'Initial Request',
      in_bearbeitung: 'In Progress',
      beratung_geplant: 'Consultation Planned',
      abgeschlossen: 'Completed',
      kein_interesse: 'Not Interested',
      convert_to_deal: 'Convert to Deal'
    },
    priorities: {
      low: 'Low',
      medium: 'Medium',
      high: 'High'
    }
  },
  deals: {
    title: 'Deals',
    newDeal: 'New Deal',
    value: 'Value',
    stage: 'Stage',
    probability: 'Probability',
    expectedCloseDate: 'Expected Close Date',
    addDeal: 'Add Deal',
    editDeal: 'Edit Deal',
    deleteDeal: 'Delete Deal',
    products: {
      title: 'Products',
      name: 'Product Name',
      quantity: 'Quantity',
      price: 'Price',
      add: 'Add Product',
      total: 'Total'
    },
    stages: {
      angebot_erstellt: 'Offer Created',
      angebot_verhandeln: 'Negotiating Offer',
      abschlussbereit: 'Ready to Close',
      montage_fixiert: 'Installation Fixed',
      projekt_fertig: 'Project Complete',
      angebot_abgelehnt: 'Offer Rejected'
    }
  },
  customers: {
    title: 'Customers',
    company: 'Company',
    industry: 'Industry',
    website: 'Website',
    annualRevenue: 'Annual Revenue',
    employeeCount: 'Employee Count'
  },
  calendar: {
    title: 'Calendar',
    allDay: 'All Day',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    types: {
      meeting: 'Meeting',
      call: 'Call',
      task: 'Task',
      deadline: 'Deadline'
    }
  },
  kanban: {
    column: 'Column',
    items: 'Items',
    item_count: '{{count}} Items',
    empty_column: 'Empty Column',
    drop_items_here: 'Drop Items Here',
    drag_item: 'Drag to Move',
    move_item: 'Move Item'
  },
  common: {
    name: 'Name',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    edit: 'Edit',
    create: 'Create',
    search: 'Search',
    loading: 'Loading...',
    error: 'Error',
    success: 'Success',
    confirm: 'Confirm',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    description: 'Description',
    date: 'Date',
    actions: 'Actions',
    status: 'Status',
    type: 'Type',
    details: 'Details',
    noData: 'No Data Available',
    currency: '$',
    upload: 'Upload',
    remove: 'Remove',
    change: 'Change'
  }
};

const initOptions: InitOptions = {
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
    format: (value: unknown, format: string | undefined) => {
      if (!format) return String(value);
      
      switch (format) {
        case 'number':
          return typeof value === 'number' 
            ? new Intl.NumberFormat('en-US').format(value)
            : String(value);
        case 'currency':
          return typeof value === 'number'
            ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(value)
            : String(value);
        case 'date':
          return value instanceof Date
            ? new Intl.DateTimeFormat('en-US').format(value)
            : String(value);
        default:
          return String(value);
      }
    }
  },
  resources: {
    en: {
      translation: translations
    }
  }
};

i18n
  .use(initReactI18next)
  .init(initOptions);

export default i18n;
