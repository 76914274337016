import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginForm } from '../components/auth/login-form';
import { useAuth } from '../hooks/useAuth';

export function Login() {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Get the redirect path from location state, or default to dashboard
  const from = (location.state as any)?.from?.pathname || '/dashboard';

  useEffect(() => {
    // If already authenticated, redirect to the intended destination
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  const handleLogin = async (email: string, password: string) => {
    try {
      await login(email, password);
      // Navigation will be handled by the useEffect above
    } catch (error: any) {
      throw error;
    }
  };

  return <LoginForm onLogin={handleLogin} />;
}
