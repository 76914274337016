import { useState } from "react"
import { Plus, AlertCircle, TableIcon, KanbanSquare } from "lucide-react"
import { useTranslation } from "react-i18next"
import { useLeads } from "../hooks/useLeads"
import { useDeals } from "../hooks/useDeals"
import { useCalendar } from "../hooks/useCalendar"
import { DataTable } from "../components/data-table"
import { createColumns } from "./leads-columns"
import { LeadForm } from "../components/leads/lead-form"
import { LeadDetails } from "../components/leads/lead-details"
import { leadsApi } from "../lib/api"
import { KanbanBoard, KanbanItem, LeadStatus } from "../components/kanban/kanban-board"
import type { Lead, CreateLeadDto, CreateCalendarEventDto } from "../types/api"
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui"

type ViewMode = 'table' | 'kanban'

export default function Leads() {
  const { t } = useTranslation()
  const { leads, isLoading, error, refetch } = useLeads()
  const { createEvent } = useCalendar()
  const { refetch: refetchDeals } = useDeals()
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [viewMode, setViewMode] = useState<ViewMode>('kanban')

  const handleCreate = async (data: Partial<Lead> & { consultationDate?: string }) => {
    try {
      setIsSubmitting(true)
      const createData: CreateLeadDto = {
        title: data.title!,
        name: data.name!,
        email: data.email!,
        phone: data.phone!,
        company: data.company!,
        status: data.status || 'erste-anfrage',
        priority: data.priority || 'medium',
      }
      const newLead = await leadsApi.create(createData)
      await refetch()
      setIsCreateModalOpen(false)

      if (data.status === 'beratung-geplant' && data.consultationDate && newLead) {
        await createConsultationEvent({
          ...newLead,
          consultationDate: data.consultationDate
        })
      }
    } catch (err) {
      console.error('Failed to create lead:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleUpdate = async (data: Partial<Lead> & { consultationDate?: string }) => {
    if (!selectedLead) return

    try {
      setIsSubmitting(true)
      const updatedLead = await leadsApi.update(selectedLead._id, data)
      await refetch()
      setIsEditModalOpen(false)

      if (data.status === 'beratung-geplant' && data.consultationDate && updatedLead) {
        await createConsultationEvent({
          ...updatedLead,
          consultationDate: data.consultationDate
        })
      }
    } catch (err) {
      console.error('Failed to update lead:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!selectedLead) return

    try {
      setIsSubmitting(true)
      await leadsApi.delete(selectedLead._id)
      await refetch()
      setIsDeleteDialogOpen(false)
      setSelectedLead(null)
    } catch (err) {
      console.error('Failed to delete lead:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleStatusUpdate = async (leadId: string, status: Lead['status'] | 'convert-to-deal') => {
    try {
      const lead = leads.find(l => l._id === leadId)
      if (!lead) return

      if (status === 'convert-to-deal') {
        await leadsApi.convertToDeal(leadId, {})
        await refetchDeals()
      } else {
        await leadsApi.updateStatus(leadId, status)
        
        if (status === 'beratung-geplant') {
          await createConsultationEvent(lead)
        }
      }
      
      await refetch()
    } catch (err) {
      console.error('Failed to update lead status:', err)
    }
  }

  const handleKanbanChange = async (items: KanbanItem<LeadStatus>[]) => {
    try {
      const changedItem = items.find(item => {
        const currentLead = leads.find(l => l._id === item.id)
        return currentLead && currentLead.status !== item.status
      })

      if (changedItem) {
        await handleStatusUpdate(changedItem.id, changedItem.status)
      }
    } catch (err) {
      console.error('Failed to update lead status:', err)
    }
  }

  const handleKanbanEdit = (item: KanbanItem<LeadStatus>) => {
    const lead = leads.find(l => l._id === item.id)
    if (lead) {
      openEditModal(lead)
    }
  }

  const createConsultationEvent = async (lead: Lead & { consultationDate?: string }) => {
    try {
      const eventData: CreateCalendarEventDto = {
        title: `Consultation: ${lead.title}`,
        description: `Consultation meeting for ${lead.company}`,
        startTime: lead.consultationDate || new Date().toISOString(),
        endTime: lead.consultationDate 
          ? new Date(new Date(lead.consultationDate).getTime() + 60 * 60 * 1000).toISOString()
          : new Date(Date.now() + 60 * 60 * 1000).toISOString(),
        type: 'meeting',
        status: 'scheduled',
        priority: lead.priority,
        organizer: '',
        attendees: [{
          name: lead.name,
          email: lead.email,
          status: 'pending'
        }],
        relatedTo: {
          type: 'lead',
          id: lead._id
        },
        reminders: [{
          type: 'email',
          time: 60
        }],
        notes: ''
      }
      
      await createEvent(eventData)
    } catch (err) {
      console.error('Failed to create consultation event:', err)
    }
  }

  const openEditModal = (lead: Lead) => {
    setSelectedLead(lead)
    setIsEditModalOpen(true)
  }

  const openDetailsModal = (lead: Lead) => {
    setSelectedLead(lead)
    setIsDetailsModalOpen(true)
  }

  const openDeleteDialog = (lead: Lead) => {
    setSelectedLead(lead)
    setIsDeleteDialogOpen(true)
  }

  const columns = createColumns({
    onStatusChange: handleStatusUpdate,
    onEdit: openEditModal,
    onView: openDetailsModal,
    onDelete: openDeleteDialog,
  })

  const kanbanItems: KanbanItem<LeadStatus>[] = leads.map(lead => ({
    id: lead._id,
    title: lead.title,
    subtitle: `${lead.name} - ${lead.company}`,
    status: lead.status,
    priority: lead.priority,
  }))

  if (error) {
    return (
      <div className="p-8">
        <div className="rounded-xl border bg-card p-6 text-center">
          <AlertCircle className="mx-auto h-8 w-8 text-red-600" />
          <h3 className="mt-2 text-xl font-semibold text-red-600">{t('common.error')}</h3>
          <p className="text-sm text-muted-foreground mt-2">{error.message}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">{t('leads.title')}</h1>
          <div className="flex gap-4">
            <div className="flex rounded-lg border border-gray-200">
              <Button
                variant={viewMode === 'table' ? 'default' : 'ghost'}
                className="rounded-r-none"
                onClick={() => setViewMode('table')}
              >
                <TableIcon className="mr-2 h-4 w-4" />
                {t('common.type')}
              </Button>
              <Button
                variant={viewMode === 'kanban' ? 'default' : 'ghost'}
                className="rounded-l-none"
                onClick={() => setViewMode('kanban')}
              >
                <KanbanSquare className="mr-2 h-4 w-4" />
                Kanban
              </Button>
            </div>
            <Button className="bg-black hover:bg-gray-800" onClick={() => setIsCreateModalOpen(true)}>
              <Plus className="mr-2 h-4 w-4" />
              {t('leads.addLead')}
            </Button>
          </div>
        </div>
      </div>
      
      <div className="flex-1 min-h-0 px-8 pb-8">
        <div className="h-full rounded-xl border bg-card">
          {viewMode === 'table' ? (
            <div className="p-6 h-full">
              <DataTable
                columns={columns}
                data={leads}
                searchColumn="name"
                isLoading={isLoading}
              />
            </div>
          ) : (
            <div className="p-6 h-full overflow-x-auto">
              <KanbanBoard<LeadStatus>
                items={kanbanItems}
                onChange={handleKanbanChange}
                type="lead"
                onEdit={handleKanbanEdit}
              />
            </div>
          )}
        </div>
      </div>

      <Dialog open={isCreateModalOpen} onOpenChange={setIsCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('leads.newLead')}</DialogTitle>
          </DialogHeader>
          <LeadForm
            onSubmit={handleCreate}
            onCancel={() => setIsCreateModalOpen(false)}
            isLoading={isSubmitting}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('leads.editLead')}</DialogTitle>
          </DialogHeader>
          <LeadForm
            initialData={selectedLead || undefined}
            onSubmit={handleUpdate}
            onCancel={() => setIsEditModalOpen(false)}
            isLoading={isSubmitting}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDetailsModalOpen} onOpenChange={setIsDetailsModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('common.details')}</DialogTitle>
          </DialogHeader>
          {selectedLead && <LeadDetails lead={selectedLead} />}
        </DialogContent>
      </Dialog>

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('common.confirm')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('common.delete')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('common.loading') : t('common.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
