import { useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import type { Customer } from '../../types/api';

interface CustomerFormProps {
  initialData?: Partial<Customer>;
  onSubmit: (data: Partial<Customer>) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
}

export function CustomerForm({ initialData, onSubmit, onCancel, isLoading }: CustomerFormProps) {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    email: initialData?.email || '',
    phone: initialData?.phone || '',
    company: initialData?.company || '',
    address: {
      street: initialData?.address?.street || '',
      city: initialData?.address?.city || '',
      state: initialData?.address?.state || '',
      postalCode: initialData?.address?.postalCode || '',
      country: initialData?.address?.country || '',
    },
    type: initialData?.type || 'individual',
    status: initialData?.status || 'prospect',
    assignedTo: initialData?.assignedTo || '',
    totalRevenue: initialData?.totalRevenue || 0,
    lastContact: initialData?.lastContact ? new Date(initialData.lastContact).toISOString().split('T')[0] : '',
    notes: initialData?.notes || '',
    tags: initialData?.tags || [],
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleAddressChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      address: { ...prev.address, [field]: value },
    }));
  };

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tags = e.target.value.split(',').map(tag => tag.trim());
    setFormData(prev => ({ ...prev, tags }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Name</label>
          <Input
            value={formData.name}
            onChange={e => handleChange('name', e.target.value)}
            placeholder="Customer name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Email</label>
          <Input
            type="email"
            value={formData.email}
            onChange={e => handleChange('email', e.target.value)}
            placeholder="Email address"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Phone</label>
          <Input
            type="tel"
            value={formData.phone}
            onChange={e => handleChange('phone', e.target.value)}
            placeholder="Phone number"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Company</label>
          <Input
            value={formData.company}
            onChange={e => handleChange('company', e.target.value)}
            placeholder="Company name"
            required
          />
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="font-medium">Address</h3>
        <Input
          value={formData.address.street}
          onChange={e => handleAddressChange('street', e.target.value)}
          placeholder="Street address"
          required
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            value={formData.address.city}
            onChange={e => handleAddressChange('city', e.target.value)}
            placeholder="City"
            required
          />
          <Input
            value={formData.address.state}
            onChange={e => handleAddressChange('state', e.target.value)}
            placeholder="State"
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Input
            value={formData.address.postalCode}
            onChange={e => handleAddressChange('postalCode', e.target.value)}
            placeholder="Postal code"
            required
          />
          <Input
            value={formData.address.country}
            onChange={e => handleAddressChange('country', e.target.value)}
            placeholder="Country"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Type</label>
          <Select
            value={formData.type}
            onValueChange={(value: Customer['type']) => handleChange('type', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="individual">Individual</SelectItem>
              <SelectItem value="business">Business</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Status</label>
          <Select
            value={formData.status}
            onValueChange={(value: Customer['status']) => handleChange('status', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="active">Active</SelectItem>
              <SelectItem value="inactive">Inactive</SelectItem>
              <SelectItem value="prospect">Prospect</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">Assigned To</label>
          <Input
            value={formData.assignedTo}
            onChange={e => handleChange('assignedTo', e.target.value)}
            placeholder="Assigned to"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Total Revenue</label>
          <Input
            type="number"
            value={formData.totalRevenue}
            onChange={e => handleChange('totalRevenue', parseFloat(e.target.value))}
            placeholder="Total revenue"
            min="0"
            step="0.01"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Last Contact</label>
        <Input
          type="date"
          value={formData.lastContact}
          onChange={e => handleChange('lastContact', e.target.value)}
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Tags</label>
        <Input
          value={formData.tags.join(', ')}
          onChange={handleTagsChange}
          placeholder="Enter tags separated by commas"
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Notes</label>
        <textarea
          value={formData.notes}
          onChange={e => handleChange('notes', e.target.value)}
          placeholder="Additional notes"
          className="w-full min-h-[100px] px-3 py-2 rounded-md border"
        />
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Saving...' : initialData ? 'Update Customer' : 'Create Customer'}
        </Button>
      </div>
    </form>
  );
}
