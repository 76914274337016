import { useState } from 'react';
import { Badge } from '../ui/badge';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { leadsApi } from '../../lib/api';
import type { Lead } from '../../types/api';

interface LeadDetailsProps {
  lead: Lead;
  onUpdate?: () => void;
}

const statusColorMap: Record<Lead['status'], string> = {
  'erste-anfrage': 'bg-blue-500',
  'in-bearbeitung': 'bg-yellow-500',
  'beratung-geplant': 'bg-purple-500',
  'abgeschlossen': 'bg-green-500',
  'kein-interesse': 'bg-red-500',
};

const priorityColorMap: Record<Lead['priority'], string> = {
  low: 'bg-gray-500',
  medium: 'bg-yellow-500',
  high: 'bg-red-500',
};

export function LeadDetails({ lead, onUpdate }: LeadDetailsProps) {
  const [isConverting, setIsConverting] = useState(false);
  const [consultationDate, setConsultationDate] = useState<string>(
    lead.consultationDate ? new Date(lead.consultationDate).toISOString().slice(0, 16) : ''
  );

  const handleConvertToDeal = async () => {
    try {
      setIsConverting(true);
      await leadsApi.convertToDeal(lead._id, {});
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error converting lead to deal:', error);
    } finally {
      setIsConverting(false);
    }
  };

  const handleConsultationDateChange = async (date: string) => {
    try {
      setConsultationDate(date);
      await leadsApi.update(lead._id, {
        ...lead,
        consultationDate: date ? new Date(date).toISOString() : undefined,
        status: date ? 'beratung-geplant' : lead.status
      });
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error updating consultation date:', error);
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <h2 className="text-2xl font-bold">{lead.title}</h2>
          <div className="mt-2 flex gap-2">
            <Badge className={`${statusColorMap[lead.status]} text-white`}>
              {lead.status.replace(/-/g, ' ')}
            </Badge>
            <Badge className={`${priorityColorMap[lead.priority]} text-white`}>
              {lead.priority}
            </Badge>
          </div>
        </div>

        <div className="grid gap-4 pt-4">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Contact Information</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Name:</span> {lead.name}
              </p>
              <p>
                <span className="font-medium">Email:</span>{' '}
                <a href={`mailto:${lead.email}`} className="text-blue-600 hover:underline">
                  {lead.email}
                </a>
              </p>
              <p>
                <span className="font-medium">Phone:</span>{' '}
                <a href={`tel:${lead.phone}`} className="text-blue-600 hover:underline">
                  {lead.phone}
                </a>
              </p>
              <p>
                <span className="font-medium">Company:</span> {lead.company}
              </p>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Consultation Date</h3>
            <div className="mt-2">
              <Input
                type="datetime-local"
                value={consultationDate}
                onChange={(e) => handleConsultationDateChange(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Timeline</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Created:</span>{' '}
                {new Date(lead.createdAt).toLocaleDateString()}
              </p>
              <p>
                <span className="font-medium">Last Updated:</span>{' '}
                {new Date(lead.updatedAt).toLocaleDateString()}
              </p>
            </div>
          </div>

          {!lead.convertedToDeal && (
            <div className="pt-4">
              <Button
                onClick={handleConvertToDeal}
                disabled={isConverting}
                className="w-full bg-green-600 hover:bg-green-700 text-white"
              >
                {isConverting ? 'Converting...' : 'Convert to Deal'}
              </Button>
            </div>
          )}

          {lead.convertedToDeal && lead.associatedDeal && (
            <div className="pt-4">
              <Badge className="bg-green-600 text-white">
                Converted to Deal on {new Date(lead.conversionDate!).toLocaleDateString()}
              </Badge>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
