import { Badge } from '../ui/badge';
import { Card } from '../ui/card';
import type { Deal } from '../../types/api';

interface DealDetailsProps {
  deal: Deal;
}

const stageColorMap: Record<Deal['stage'], string> = {
  'angebot-erstellt': 'bg-blue-500',
  'angebot-verhandeln': 'bg-purple-500',
  'abschlussbereit': 'bg-yellow-500',
  'montage-fixiert': 'bg-green-500',
  'projekt-fertig': 'bg-green-500',
  'angebot-abgelehnt': 'bg-red-500',
};

const priorityColorMap: Record<Deal['priority'], string> = {
  low: 'bg-gray-500',
  medium: 'bg-yellow-500',
  high: 'bg-red-500',
};

export function DealDetails({ deal }: DealDetailsProps) {
  const totalValue = deal.products.reduce((sum, product) => {
    return sum + (product.quantity * product.price);
  }, 0);

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <h2 className="text-2xl font-bold">{deal.title}</h2>
          <div className="mt-2 flex gap-2">
            <Badge className={`${stageColorMap[deal.stage]} text-white`}>
              {deal.stage.replace(/-/g, ' ')}
            </Badge>
            <Badge className={`${priorityColorMap[deal.priority]} text-white`}>
              {deal.priority}
            </Badge>
          </div>
        </div>

        <div className="grid gap-4 pt-4">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Deal Information</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Company:</span> {deal.company}
              </p>
              <p>
                <span className="font-medium">Assigned To:</span> {deal.assignedTo}
              </p>
              <p>
                <span className="font-medium">Expected Close Date:</span>{' '}
                {new Date(deal.expectedCloseDate).toLocaleDateString()}
              </p>
              <p>
                <span className="font-medium">Total Value:</span>{' '}
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(totalValue)}
              </p>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Products</h3>
            <div className="mt-2 space-y-2">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="text-left text-sm font-medium text-gray-500">Product</th>
                    <th className="text-right text-sm font-medium text-gray-500">Quantity</th>
                    <th className="text-right text-sm font-medium text-gray-500">Price</th>
                    <th className="text-right text-sm font-medium text-gray-500">Total</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {deal.products.map((product, index) => (
                    <tr key={index}>
                      <td className="py-2">{product.name}</td>
                      <td className="text-right">{product.quantity}</td>
                      <td className="text-right">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(product.price)}
                      </td>
                      <td className="text-right">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(product.quantity * product.price)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {deal.notes && (
            <div>
              <h3 className="text-sm font-medium text-gray-500">Notes</h3>
              <p className="mt-2 whitespace-pre-wrap">{deal.notes}</p>
            </div>
          )}

          <div>
            <h3 className="text-sm font-medium text-gray-500">Timeline</h3>
            <div className="mt-2 space-y-2">
              <p>
                <span className="font-medium">Created:</span>{' '}
                {new Date(deal.createdAt).toLocaleDateString()}
              </p>
              <p>
                <span className="font-medium">Last Updated:</span>{' '}
                {new Date(deal.updatedAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
