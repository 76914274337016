import { useState, useEffect } from 'react';
import { dealsApi } from '../lib/api';
import type { Deal } from '../types/api';

interface UseDealsReturn {
  deals: Deal[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export function useDeals(): UseDealsReturn {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  async function fetchDeals() {
    try {
      setIsLoading(true);
      const data = await dealsApi.getAll();
      setDeals(data ?? []);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch deals'));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchDeals();
  }, []);

  return {
    deals,
    isLoading,
    error,
    refetch: fetchDeals
  };
}
