import { useState, useEffect } from 'react';
import { leadsApi } from '../lib/api';
import type { Lead } from '../types/api';

interface UseLeadsReturn {
  leads: Lead[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export function useLeads(): UseLeadsReturn {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  async function fetchLeads() {
    try {
      setIsLoading(true);
      const data = await leadsApi.getAll();
      // Filter out converted leads
      const unconvertedLeads = (data ?? []).filter(lead => !lead.convertedToDeal);
      setLeads(unconvertedLeads);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch leads'));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchLeads();
  }, []);

  return {
    leads,
    isLoading,
    error,
    refetch: fetchLeads
  };
}
