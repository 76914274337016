import { Plus } from "lucide-react"
import { useState } from "react"
import { useCalendar } from "../hooks/useCalendar"
import { Button } from "../components/ui/button"
import { Badge } from "../components/ui/badge"
import { formatDate } from "../lib/utils"
import type { CalendarEvent, CreateCalendarEventDto, UpdateCalendarEventDto } from "../types/api"
import { CalendarView } from "../components/calendar/calendar-view"
import { CalendarEventForm } from "../components/calendar/calendar-event-form"
import { CalendarEventDetails } from "../components/calendar/calendar-event-details"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog"
import { useLeads } from "../hooks/useLeads"
import { useDeals } from "../hooks/useDeals"
import { utcToZonedTime } from 'date-fns-tz'

const TIMEZONE = 'Europe/Berlin';

const eventTypeColorMap: Record<CalendarEvent['type'], string> = {
  'meeting': 'bg-blue-500',
  'task': 'bg-green-500',
  'call': 'bg-purple-500',
  'deadline': 'bg-red-500',
}

const priorityColorMap: Record<CalendarEvent['priority'], string> = {
  low: 'bg-gray-500',
  medium: 'bg-yellow-500',
  high: 'bg-red-500',
}

export default function CalendarPage() {
  const { events, upcomingEvents, isLoading, error, createEvent, updateEvent, deleteEvent } = useCalendar()
  const { leads } = useLeads()
  const { deals } = useDeals()
  const [isEventFormOpen, setIsEventFormOpen] = useState(false)
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>()
  const [selectedSlot, setSelectedSlot] = useState<{ start: Date; end: Date } | undefined>()

  if (error) {
    return (
      <div className="p-8">
        <div className="rounded-xl border bg-card p-6 text-center">
          <h3 className="text-xl font-semibold text-red-600">Error loading calendar events</h3>
          <p className="text-sm text-muted-foreground mt-2">{error.message}</p>
        </div>
      </div>
    )
  }

  const handleEventClick = (event: CalendarEvent) => {
    setSelectedEvent(event)
    setIsEventDetailsOpen(true)
  }

  const handleSelectSlot = (start: Date, end: Date) => {
    // Convert UTC dates to Berlin timezone for form display
    const berlinStart = utcToZonedTime(start, TIMEZONE);
    const berlinEnd = utcToZonedTime(end, TIMEZONE);
    
    setSelectedSlot({ start: berlinStart, end: berlinEnd })
    setSelectedEvent(undefined)
    setIsEventFormOpen(true)
  }

  const handleEventSubmit = async (data: CreateCalendarEventDto | UpdateCalendarEventDto) => {
    try {
      if (selectedEvent) {
        await updateEvent(selectedEvent._id, data as UpdateCalendarEventDto)
      } else {
        await createEvent(data as CreateCalendarEventDto)
      }
      
      setIsEventFormOpen(false)
      setSelectedEvent(undefined)
      setSelectedSlot(undefined)
    } catch (error) {
      console.error('Failed to save event:', error)
    }
  }

  const handleEventDelete = async (id: string) => {
    try {
      await deleteEvent(id)
      setIsEventDetailsOpen(false)
      setSelectedEvent(undefined)
    } catch (error) {
      console.error('Failed to delete event:', error)
    }
  }

  const handleEditClick = () => {
    setIsEventDetailsOpen(false)
    setIsEventFormOpen(true)
  }

  // Format the initial data for the form
  const getInitialFormData = (): Partial<CalendarEvent> | undefined => {
    if (selectedEvent) {
      return selectedEvent
    }
    if (selectedSlot) {
      return {
        startTime: selectedSlot.start.toISOString(),
        endTime: selectedSlot.end.toISOString(),
        type: 'meeting',
        status: 'scheduled',
        priority: 'medium',
        attendees: [],
        reminders: []
      }
    }
    return undefined
  }

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Calendar</h1>
        <Button 
          className="bg-black hover:bg-gray-800"
          onClick={() => {
            setSelectedEvent(undefined)
            setSelectedSlot(undefined)
            setIsEventFormOpen(true)
          }}
        >
          <Plus className="mr-2 h-4 w-4" />
          Add Event
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-[1fr_300px]">
        <div className="space-y-6">
          <div className="rounded-xl border bg-card">
            <CalendarView
              events={events}
              onEventClick={handleEventClick}
              onSelectSlot={handleSelectSlot}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="rounded-xl border bg-card p-6">
          <h2 className="text-xl font-semibold mb-4">Upcoming Events</h2>
          {isLoading ? (
            <div className="h-24 flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : upcomingEvents.length > 0 ? (
            <div className="space-y-4">
              {upcomingEvents.map((event) => (
                <div 
                  key={event._id} 
                  className="flex items-center justify-between p-4 border rounded-lg cursor-pointer hover:bg-gray-50"
                  onClick={() => handleEventClick(event)}
                >
                  <div>
                    <h3 className="font-medium">{event.title}</h3>
                    <p className="text-sm text-muted-foreground">
                      {formatDate(event.startTime)}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Badge className={`${eventTypeColorMap[event.type]} text-white`}>
                      {event.type}
                    </Badge>
                    <Badge className={`${priorityColorMap[event.priority]} text-white`}>
                      {event.priority}
                    </Badge>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-muted-foreground">No upcoming events</p>
          )}
        </div>
      </div>

      <Dialog open={isEventFormOpen} onOpenChange={setIsEventFormOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>
              {selectedEvent ? 'Edit Event' : 'Create New Event'}
            </DialogTitle>
          </DialogHeader>
          <CalendarEventForm
            initialData={getInitialFormData()}
            onSubmit={handleEventSubmit}
            onCancel={() => {
              setIsEventFormOpen(false)
              setSelectedEvent(undefined)
              setSelectedSlot(undefined)
            }}
            isLoading={isLoading}
            leads={leads}
            deals={deals}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isEventDetailsOpen} onOpenChange={setIsEventDetailsOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>Event Details</DialogTitle>
          </DialogHeader>
          {selectedEvent && (
            <>
              <CalendarEventDetails 
                event={selectedEvent} 
                onDelete={handleEventDelete}
              />
              <div className="flex justify-end space-x-2 mt-4">
                <Button variant="outline" onClick={() => setIsEventDetailsOpen(false)}>
                  Close
                </Button>
                <Button onClick={handleEditClick}>
                  Edit Event
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
