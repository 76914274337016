import { useState } from "react"
import { Plus, AlertCircle, TableIcon, KanbanSquare } from "lucide-react"
import { useTranslation } from "react-i18next"
import { useDeals } from "../hooks/useDeals"
import { useCalendar } from "../hooks/useCalendar"
import { DataTable } from "../components/data-table"
import { createColumns } from "./deals-columns"
import { DealForm } from "../components/deals/deal-form"
import { DealDetails } from "../components/deals/deal-details"
import { dealsApi } from "../lib/api"
import { KanbanBoard, KanbanItem, DealStatus } from "../components/kanban/kanban-board"
import type { Deal, CreateDealDto, CreateCalendarEventDto } from "../types/api"
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui"

type ViewMode = 'table' | 'kanban'

export default function Deals() {
  const { t } = useTranslation()
  const { deals, isLoading, error, refetch } = useDeals()
  const { createEvent } = useCalendar()
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [viewMode, setViewMode] = useState<ViewMode>('kanban')

  const createInstallationEvent = async (dealData: Deal & { installationDate?: string }) => {
    try {
      const eventData: CreateCalendarEventDto = {
        title: `Installation: ${dealData.title}`,
        description: `Installation for ${dealData.company}`,
        startTime: dealData.installationDate || dealData.expectedCloseDate,
        endTime: dealData.installationDate 
          ? new Date(new Date(dealData.installationDate).getTime() + 4 * 60 * 60 * 1000).toISOString()
          : new Date(new Date(dealData.expectedCloseDate).getTime() + 4 * 60 * 60 * 1000).toISOString(),
        type: 'deadline',
        status: 'scheduled',
        priority: dealData.priority,
        organizer: dealData.assignedTo,
        attendees: [],
        relatedTo: {
          type: 'deal',
          id: dealData._id
        },
        reminders: [{
          type: 'email',
          time: 24 * 60
        }, {
          type: 'notification',
          time: 60
        }],
        notes: `Installation appointment for ${dealData.company}\nValue: ${dealData.value}\nProducts: ${dealData.products.map(p => p.name).join(', ')}`
      }
      
      await createEvent(eventData)
    } catch (err) {
      console.error('Failed to create installation event:', err)
    }
  }

  const handleCreate = async (data: Partial<Deal> & { installationDate?: string }) => {
    try {
      setIsSubmitting(true)
      const createData: CreateDealDto = {
        title: data.title!,
        value: data.value!,
        company: data.company!,
        stage: data.stage || 'angebot-erstellt',
        priority: data.priority || 'medium',
        assignedTo: data.assignedTo!,
        expectedCloseDate: data.expectedCloseDate!,
        products: data.products || [],
        notes: data.notes || '',
      }
      const newDeal = await dealsApi.create(createData)
      await refetch()
      setIsCreateModalOpen(false)

      if (data.stage === 'montage-fixiert' && data.installationDate && newDeal) {
        await createInstallationEvent({
          ...newDeal,
          installationDate: data.installationDate
        })
      }
    } catch (err) {
      console.error('Failed to create deal:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleUpdate = async (data: Partial<Deal> & { installationDate?: string }) => {
    if (!selectedDeal) return

    try {
      setIsSubmitting(true)
      const updatedDeal = await dealsApi.update(selectedDeal._id, data)
      await refetch()
      setIsEditModalOpen(false)

      if (data.stage === 'montage-fixiert' && data.installationDate && updatedDeal) {
        await createInstallationEvent({
          ...updatedDeal,
          installationDate: data.installationDate
        })
      }
    } catch (err) {
      console.error('Failed to update deal:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!selectedDeal) return

    try {
      setIsSubmitting(true)
      await dealsApi.delete(selectedDeal._id)
      await refetch()
      setIsDeleteDialogOpen(false)
      setSelectedDeal(null)
    } catch (err) {
      console.error('Failed to delete deal:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleStageUpdate = async (dealId: string, stage: Deal['stage']) => {
    try {
      const deal = deals.find(d => d._id === dealId)
      if (!deal) return

      await dealsApi.updateStage(dealId, stage)
      
      if (stage === 'montage-fixiert') {
        setSelectedDeal(deal)
        setIsEditModalOpen(true)
      }
      
      await refetch()
    } catch (err) {
      console.error('Failed to update deal stage:', err)
    }
  }

  const handleKanbanChange = async (items: KanbanItem<DealStatus>[]) => {
    try {
      const changedItem = items.find(item => {
        const currentDeal = deals.find(d => d._id === item.id)
        return currentDeal && currentDeal.stage !== item.status
      })

      if (changedItem) {
        await handleStageUpdate(changedItem.id, changedItem.status)
      }
    } catch (err) {
      console.error('Failed to update deal stage:', err)
    }
  }

  const handleKanbanEdit = (item: KanbanItem<DealStatus>) => {
    const deal = deals.find(d => d._id === item.id)
    if (deal) {
      openEditModal(deal)
    }
  }

  const openEditModal = (deal: Deal) => {
    setSelectedDeal(deal)
    setIsEditModalOpen(true)
  }

  const openDetailsModal = (deal: Deal) => {
    setSelectedDeal(deal)
    setIsDetailsModalOpen(true)
  }

  const openDeleteDialog = (deal: Deal) => {
    setSelectedDeal(deal)
    setIsDeleteDialogOpen(true)
  }

  const columns = createColumns({
    onStageChange: handleStageUpdate,
    onEdit: openEditModal,
    onView: openDetailsModal,
    onDelete: openDeleteDialog,
  })

  const kanbanItems: KanbanItem<DealStatus>[] = deals.map(deal => ({
    id: deal._id,
    title: deal.title,
    subtitle: `${deal.company} - ${t('common.currency')}${deal.value.toLocaleString()}`,
    status: deal.stage,
    priority: deal.priority,
  }))

  if (error) {
    return (
      <div className="p-8">
        <div className="rounded-xl border bg-card p-6 text-center">
          <AlertCircle className="mx-auto h-8 w-8 text-red-600" />
          <h3 className="mt-2 text-xl font-semibold text-red-600">{t('common.error')}</h3>
          <p className="text-sm text-muted-foreground mt-2">{error.message}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">{t('deals.title')}</h1>
          <div className="flex gap-4">
            <div className="flex rounded-lg border border-gray-200">
              <Button
                variant={viewMode === 'table' ? 'default' : 'ghost'}
                className="rounded-r-none"
                onClick={() => setViewMode('table')}
              >
                <TableIcon className="mr-2 h-4 w-4" />
                {t('common.type')}
              </Button>
              <Button
                variant={viewMode === 'kanban' ? 'default' : 'ghost'}
                className="rounded-l-none"
                onClick={() => setViewMode('kanban')}
              >
                <KanbanSquare className="mr-2 h-4 w-4" />
                Kanban
              </Button>
            </div>
            <Button className="bg-black hover:bg-gray-800" onClick={() => setIsCreateModalOpen(true)}>
              <Plus className="mr-2 h-4 w-4" />
              {t('deals.addDeal')}
            </Button>
          </div>
        </div>
      </div>
      
      <div className="flex-1 min-h-0 px-8 pb-8">
        <div className="h-full rounded-xl border bg-card">
          {viewMode === 'table' ? (
            <div className="p-6 h-full">
              <DataTable
                columns={columns}
                data={deals}
                searchColumn="title"
                isLoading={isLoading}
              />
            </div>
          ) : (
            <div className="p-6 h-full overflow-x-auto">
              <KanbanBoard<DealStatus>
                items={kanbanItems}
                onChange={handleKanbanChange}
                type="deal"
                onEdit={handleKanbanEdit}
              />
            </div>
          )}
        </div>
      </div>

      <Dialog open={isCreateModalOpen} onOpenChange={setIsCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('deals.newDeal')}</DialogTitle>
          </DialogHeader>
          <DealForm
            onSubmit={handleCreate}
            onCancel={() => setIsCreateModalOpen(false)}
            isLoading={isSubmitting}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('deals.editDeal')}</DialogTitle>
          </DialogHeader>
          <DealForm
            initialData={selectedDeal || undefined}
            onSubmit={handleUpdate}
            onCancel={() => setIsEditModalOpen(false)}
            isLoading={isSubmitting}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDetailsModalOpen} onOpenChange={setIsDetailsModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>{t('common.details')}</DialogTitle>
          </DialogHeader>
          {selectedDeal && <DealDetails deal={selectedDeal} />}
        </DialogContent>
      </Dialog>

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('common.confirm')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('common.delete')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('common.loading') : t('common.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
