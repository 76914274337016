import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts"
import { Deal } from "../types/api"
import { formatCurrency } from "../lib/utils"
import { Loader2 } from "lucide-react"

interface OverviewProps {
  deals: Deal[]
  isLoading: boolean
}

export function Overview({ deals, isLoading }: OverviewProps) {
  if (isLoading) {
    return (
      <div className="h-[350px] flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  // Process deals data for the chart
  const chartData = deals.reduce((acc: Record<string, number>, deal) => {
    const month = new Date(deal.createdAt).toLocaleString('default', { month: 'short' });
    acc[month] = (acc[month] || 0) + deal.value;
    return acc;
  }, {});

  // Convert to array format for Recharts
  const data = Object.entries(chartData).map(([name, total]) => ({
    name,
    total
  }));

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => `${formatCurrency(value)}`}
        />
        <Tooltip
          formatter={(value: number) => [formatCurrency(value), "Revenue"]}
          cursor={{ fill: 'transparent' }}
        />
        <Bar
          dataKey="total"
          fill="currentColor"
          radius={[4, 4, 0, 0]}
          className="fill-primary"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
