import { useState, useMemo } from 'react'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { KanbanColumn } from './kanban-column'
import { useTranslation } from 'react-i18next'

export type LeadStatus = 'erste-anfrage' | 'in-bearbeitung' | 'beratung-geplant' | 'abgeschlossen' | 'kein-interesse' | 'convert-to-deal'
export type DealStatus = 'angebot-erstellt' | 'angebot-verhandeln' | 'abschlussbereit' | 'montage-fixiert' | 'projekt-fertig' | 'angebot-abgelehnt'
export type Priority = 'low' | 'medium' | 'high'

export interface KanbanItem<T extends LeadStatus | DealStatus = LeadStatus | DealStatus> {
  id: string
  title: string
  status: T
  priority: Priority
  subtitle?: string
}

interface Props<T extends LeadStatus | DealStatus> {
  items: KanbanItem<T>[]
  onChange: (items: KanbanItem<T>[]) => void
  type: 'lead' | 'deal'
  onEdit?: (item: KanbanItem<T>) => void
}

type StatusTranslationKey = 
  | 'leads.statuses.erste_anfrage'
  | 'leads.statuses.in_bearbeitung'
  | 'leads.statuses.beratung_geplant'
  | 'leads.statuses.abgeschlossen'
  | 'leads.statuses.kein_interesse'
  | 'leads.statuses.convert_to_deal'
  | 'deals.stages.angebot_erstellt'
  | 'deals.stages.angebot_verhandeln'
  | 'deals.stages.abschlussbereit'
  | 'deals.stages.montage_fixiert'
  | 'deals.stages.projekt_fertig'
  | 'deals.stages.angebot_abgelehnt'

const statusTranslationMap: Record<LeadStatus | DealStatus, StatusTranslationKey> = {
  'erste-anfrage': 'leads.statuses.erste_anfrage',
  'in-bearbeitung': 'leads.statuses.in_bearbeitung',
  'beratung-geplant': 'leads.statuses.beratung_geplant',
  'abgeschlossen': 'leads.statuses.abgeschlossen',
  'kein-interesse': 'leads.statuses.kein_interesse',
  'convert-to-deal': 'leads.statuses.convert_to_deal',
  'angebot-erstellt': 'deals.stages.angebot_erstellt',
  'angebot-verhandeln': 'deals.stages.angebot_verhandeln',
  'abschlussbereit': 'deals.stages.abschlussbereit',
  'montage-fixiert': 'deals.stages.montage_fixiert',
  'projekt-fertig': 'deals.stages.projekt_fertig',
  'angebot-abgelehnt': 'deals.stages.angebot_abgelehnt'
} as const

const getStatusTranslationKey = (status: LeadStatus | DealStatus): StatusTranslationKey => {
  return statusTranslationMap[status]
}

export function KanbanBoard<T extends LeadStatus | DealStatus>({ items, onChange, type, onEdit }: Props<T>) {
  const [activeItem, setActiveItem] = useState<KanbanItem<T> | null>(null)
  const { t } = useTranslation()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 8,
      },
    })
  )

  const statuses = type === 'lead' 
    ? ['erste-anfrage', 'in-bearbeitung', 'beratung-geplant', 'abgeschlossen', 'kein-interesse', 'convert-to-deal'] as T[]
    : ['angebot-erstellt', 'angebot-verhandeln', 'abschlussbereit', 'montage-fixiert', 'projekt-fertig', 'angebot-abgelehnt'] as T[]

  const itemsByStatus = useMemo(() => {
    return items.reduce((acc, item) => {
      acc[item.status] = [...(acc[item.status] || []), item]
      return acc
    }, {} as Record<T, KanbanItem<T>[]>)
  }, [items, type])

  function onDragStart(event: DragStartEvent) {
    const item = items.find((t) => t.id === event.active.id)
    if (item) setActiveItem(item)
  }

  function onDragOver(event: DragOverEvent) {
    if (!activeItem) return

    const overStatus = event.over?.id as T | undefined
    if (!overStatus || activeItem.status === overStatus) return

    const updatedItems = items.map((item) =>
      item.id === activeItem.id ? { ...item, status: overStatus } : item
    )

    onChange(updatedItems)
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveItem(null)

    const { active, over } = event
    if (!over || active.id === over.id) return

    const activeIndex = items.findIndex((t) => t.id === active.id)
    const overIndex = items.findIndex((t) => t.id === over.id)

    if (activeIndex !== -1 && overIndex !== -1) {
      const updatedItems = arrayMove(items, activeIndex, overIndex)
      onChange(updatedItems)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
    >
      <div className="flex gap-4 overflow-x-auto pb-4">
        {statuses.map((status) => {
          const statusItems = itemsByStatus[status] || []
          const translationKey = getStatusTranslationKey(status)
          return (
            <SortableContext key={status} items={statusItems.map(item => item.id)}>
              <KanbanColumn<T>
                status={status}
                title={t(translationKey)}
                items={statusItems}
                activeItem={activeItem}
                onEdit={onEdit}
              />
            </SortableContext>
          )
        })}
      </div>
    </DndContext>
  )
}
