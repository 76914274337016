import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar"
import { Deal } from "../types/api"
import { formatCurrency } from "../lib/utils"
import { Loader2 } from "lucide-react"

interface RecentSalesProps {
  deals: Deal[]
  isLoading: boolean
}

export function RecentSales({ deals, isLoading }: RecentSalesProps) {
  if (isLoading) {
    return (
      <div className="space-y-8">
        <div className="flex items-center justify-center">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      </div>
    )
  }

  if (deals.length === 0) {
    return (
      <div className="space-y-8">
        <div className="flex items-center justify-center text-sm text-muted-foreground">
          No recent deals
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-8">
      {deals.map((deal) => (
        <div key={deal._id} className="flex items-center">
          <Avatar className="h-9 w-9">
            <AvatarImage 
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(deal.title)}`}
              alt={deal.title}
            />
            <AvatarFallback>
              {deal.title.substring(0, 2).toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <div className="ml-4 space-y-1">
            <p className="text-sm font-medium leading-none">{deal.title}</p>
            <p className="text-sm text-muted-foreground">
              {deal.company}
            </p>
          </div>
          <div className="ml-auto font-medium">
            {formatCurrency(deal.value)}
          </div>
        </div>
      ))}
    </div>
  )
}
