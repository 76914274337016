import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';

export default function Unauthorized() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg text-center">
        <div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Access Denied
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            You don't have permission to access this resource.
          </p>
        </div>
        <div className="mt-6">
          <Button
            onClick={() => navigate(-1)}
            className="w-full bg-yellow-600 hover:bg-yellow-700"
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
}
