import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Calendar } from '../ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { CalendarIcon } from 'lucide-react'
import { format } from 'date-fns'
import type { Deal } from '../../types/api'
import { formatCurrency, formatNumber } from '../../lib/utils'

interface DealFormProps {
  initialData?: Partial<Deal>;
  onSubmit: (data: Partial<Deal> & { installationDate?: string }) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
}

interface Product {
  name: string;
  quantity: number;
  price: number;
}

interface FormData {
  title: string;
  value: number;
  company: string;
  stage: Deal['stage'];
  priority: Deal['priority'];
  assignedTo: string;
  expectedCloseDate: Date;
  installationDate: Date | undefined;
  installationTime: string;
  products: Product[];
  notes: string;
}

export function DealForm({ initialData, onSubmit, onCancel, isLoading }: DealFormProps) {
  const { t } = useTranslation()
  const [formData, setFormData] = useState<FormData>({
    title: initialData?.title || '',
    value: initialData?.value || 0,
    company: initialData?.company || '',
    stage: initialData?.stage || 'angebot-erstellt',
    priority: initialData?.priority || 'medium',
    assignedTo: initialData?.assignedTo || '',
    expectedCloseDate: initialData?.expectedCloseDate ? 
      new Date(initialData.expectedCloseDate) : 
      new Date(),
    installationDate: initialData?.installationDate ?
      new Date(initialData.installationDate) :
      undefined,
    installationTime: initialData?.installationDate ?
      format(new Date(initialData.installationDate), 'HH:mm') :
      format(new Date(), 'HH:mm'),
    products: initialData?.products || [{ name: '', quantity: 1, price: 0 }],
    notes: initialData?.notes || '',
  });

  // Show/hide installation date based on stage
  const [showInstallationDate, setShowInstallationDate] = useState(formData.stage === 'montage-fixiert');

  useEffect(() => {
    setShowInstallationDate(formData.stage === 'montage-fixiert');
    // Clear installation date if stage is not 'montage-fixiert'
    if (formData.stage !== 'montage-fixiert') {
      setFormData((prev) => ({ 
        ...prev, 
        installationDate: undefined,
        installationTime: format(new Date(), 'HH:mm')
      }));
    }
  }, [formData.stage]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const submissionData = {
      ...formData,
      expectedCloseDate: formData.expectedCloseDate.toISOString(),
      installationDate: formData.installationDate ? (() => {
        const date = new Date(formData.installationDate);
        const [hours, minutes] = formData.installationTime.split(':');
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        return date.toISOString();
      })() : undefined,
      value: typeof formData.value === 'string' ? parseFloat(formData.value) : formData.value,
    };
    
    await onSubmit(submissionData);
  };

  const handleChange = (field: keyof FormData, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleProductChange = (index: number, field: keyof Product, value: string | number) => {
    const newProducts = [...formData.products];
    if (field === 'price') {
      // Convert German number format (1.234,56) to standard format (1234.56)
      const standardNumber = value.toString().replace(/\./g, '').replace(',', '.');
      newProducts[index] = { ...newProducts[index], [field]: parseFloat(standardNumber) || 0 };
    } else {
      newProducts[index] = { ...newProducts[index], [field]: value };
    }
    setFormData((prev) => ({ ...prev, products: newProducts }));
  };

  const addProduct = () => {
    setFormData((prev) => ({
      ...prev,
      products: [...prev.products, { name: '', quantity: 1, price: 0 }],
    }));
  };

  const removeProduct = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      products: prev.products.filter((_, i) => i !== index),
    }));
  };

  const calculateTotalValue = () => {
    return formData.products.reduce((total, product) => {
      return total + (product.quantity * product.price);
    }, 0);
  };

  // Update total value when products change
  const updateTotalValue = () => {
    const total = calculateTotalValue();
    if (total !== formData.value) {
      handleChange('value', total);
    }
  };

  // Call updateTotalValue whenever products change
  useEffect(() => {
    updateTotalValue();
  }, [formData.products]);

  const handleDateSelect = (field: 'expectedCloseDate', date: Date | undefined) => {
    if (date) {
      const currentDate = formData[field];
      date.setHours(currentDate.getHours());
      date.setMinutes(currentDate.getMinutes());
      handleChange(field, date);
    }
  };

  const handleTimeChange = (field: 'expectedCloseDate', timeString: string) => {
    const date = formData[field];
    const [hours, minutes] = timeString.split(':').map(Number);
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    handleChange(field, newDate);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">{t('common.name')}</label>
        <Input
          value={formData.title}
          onChange={e => handleChange('title', e.target.value)}
          placeholder={t('deals.newDeal')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('customers.company')}</label>
        <Input
          value={formData.company}
          onChange={e => handleChange('company', e.target.value)}
          placeholder={t('customers.company')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('deals.stage')}</label>
        <Select
          value={formData.stage}
          onValueChange={(value: Deal['stage']) => handleChange('stage', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder={t('deals.stage')} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="angebot-erstellt">{t('deals.stages.angebot_erstellt')}</SelectItem>
            <SelectItem value="angebot-verhandeln">{t('deals.stages.angebot_verhandeln')}</SelectItem>
            <SelectItem value="abschlussbereit">{t('deals.stages.abschlussbereit')}</SelectItem>
            <SelectItem value="montage-fixiert">{t('deals.stages.montage_fixiert')}</SelectItem>
            <SelectItem value="projekt-fertig">{t('deals.stages.projekt_fertig')}</SelectItem>
            <SelectItem value="angebot-abgelehnt">{t('deals.stages.angebot_abgelehnt')}</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {showInstallationDate && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">{t('deals.installationDate')}</label>
            <div className="flex gap-2">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="flex-1 justify-start text-left font-normal"
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {formData.installationDate ? (
                      format(formData.installationDate, 'PPP')
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 z-[60]" align="start" side="bottom" sideOffset={4}>
                  <Calendar
                    mode="single"
                    selected={formData.installationDate}
                    onSelect={(date) => {
                      if (date) {
                        handleChange('installationDate', date);
                      }
                    }}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <Input
                type="time"
                value={formData.installationTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                  handleChange('installationTime', e.target.value)
                }
                className="w-[150px]"
                required={showInstallationDate}
              />
            </div>
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium mb-1">{t('leads.priority')}</label>
        <Select
          value={formData.priority}
          onValueChange={(value: Deal['priority']) => handleChange('priority', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder={t('leads.priority')} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="low">{t('leads.priorities.low')}</SelectItem>
            <SelectItem value="medium">{t('leads.priorities.medium')}</SelectItem>
            <SelectItem value="high">{t('leads.priorities.high')}</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('leads.assignedTo')}</label>
        <Input
          value={formData.assignedTo}
          onChange={e => handleChange('assignedTo', e.target.value)}
          placeholder={t('leads.assignedTo')}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('deals.expectedCloseDate')}</label>
        <div className="flex gap-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className="flex-1 justify-start text-left font-normal"
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {format(formData.expectedCloseDate, 'PPP')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0 z-[60]" align="start" side="bottom" sideOffset={4}>
              <Calendar
                mode="single"
                selected={formData.expectedCloseDate}
                onSelect={(date) => handleDateSelect('expectedCloseDate', date)}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <Input
            type="time"
            value={format(formData.expectedCloseDate, 'HH:mm')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
              handleTimeChange('expectedCloseDate', e.target.value)
            }
            className="w-[150px]"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('deals.products.title')}</label>
        <div className="space-y-4">
          {formData.products.map((product, index) => (
            <div key={index} className="flex gap-2 items-end">
              <div className="flex-1">
                <Input
                  value={product.name}
                  onChange={e => {
                    handleProductChange(index, 'name', e.target.value);
                    updateTotalValue();
                  }}
                  placeholder={t('deals.products.name')}
                  required
                />
              </div>
              <div className="w-24">
                <Input
                  type="number"
                  value={product.quantity}
                  onChange={e => {
                    handleProductChange(index, 'quantity', parseInt(e.target.value));
                    updateTotalValue();
                  }}
                  placeholder={t('deals.products.quantity')}
                  min="1"
                  required
                />
              </div>
              <div className="w-32">
                <Input
                  type="text"
                  value={formatNumber(product.price)}
                  onChange={e => {
                    handleProductChange(index, 'price', e.target.value);
                    updateTotalValue();
                  }}
                  placeholder={t('deals.products.price')}
                  required
                />
              </div>
              {formData.products.length > 1 && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    removeProduct(index);
                    updateTotalValue();
                  }}
                  className="flex-shrink-0"
                >
                  {t('common.remove')}
                </Button>
              )}
            </div>
          ))}
          <Button type="button" variant="outline" onClick={addProduct}>
            {t('deals.products.add')}
          </Button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('deals.products.total')}</label>
        <Input
          type="text"
          value={formatCurrency(formData.value)}
          readOnly
          className="bg-gray-50"
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">{t('leads.notes')}</label>
        <textarea
          value={formData.notes}
          onChange={e => handleChange('notes', e.target.value)}
          placeholder={t('leads.notes')}
          className="w-full min-h-[100px] px-3 py-2 rounded-md border"
        />
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <Button type="button" variant="outline" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? t('common.loading') : initialData ? t('common.save') : t('common.create')}
        </Button>
      </div>
    </form>
  )
}
